import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Context } from 'utils/context/store/Store';
import { SelectBox } from 'devextreme-react/select-box';
import Tabs, { Item } from 'devextreme-react/tabs';
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
import useStructureManagement from 'utils/hooks/useStructureManagement';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
import Card from '@mui/material/Card';
import usePagePermissions from 'utils/hooks/usePagePermissions';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import Select from 'react-select';
import { Grid } from '@mui/material';
import { selectCustomStyles, a11yProps } from '../../utils/services/Helpers';
import StructureManagementDataGrid from './DataTable/StructureManagementDataGrid';
import TabPanel from 'components/TabPanel';
import CustomSkelton from 'components/Skelton/CustomSkelton';
import useBulkUpload from 'utils/hooks/useBulkUpload';

const RenderCompAndDropDown = ({ lists, routeKey }) => {
  const {
    getStructureManagement,
    deleteStructureManagement,
    updateOrCreateStructureManagement,
    isLoading
  } = useStructureManagement(routeKey);
  const { bulkUpload } = useBulkUpload(routeKey);
  const [{ structureManagement }, dispatch] = useContext(Context);
  const { getPermissionsForPage } = usePagePermissions(routeKey);

  const [value, setValue] = useState(null);
  const [displayValue, setDisplayValue] = useState(null);

  const tablesForBulkUpload = [
    'division',
    'entity',
    'vertical',
    'function',
    'country',
    'market',
    'hfm',
    'currency',
    'level',
    'entity-country-vertical-function',
    'decvl',
    'department'
  ];

  useEffect(async () => {
    if (value !== null) await getStructureManagement(value);
  }, [value]);

  const selectBoxHandle = useCallback(
    (e) => {
      if (e.event !== undefined) {
        setValue(e.value);
        const indexInArray = lists?.findIndex((eFindIndex) => eFindIndex.value === e.value);
        indexInArray !== -1 && setDisplayValue(lists?.[indexInArray].label);
      }
    },
    [lists, value, displayValue, setValue, setDisplayValue]
  );

  let options = lists ?? [];

  return (
    <>
      {options.length ? (
        <SelectBox
          width={300}
          placeholder={'Please select option... '}
          dataSource={options}
          value={value ?? null}
          searchEnabled={true}
          showClearButton={true}
          valueExpr="value"
          displayExpr="label"
          onValueChanged={(e) => selectBoxHandle(e)}
          className={`__select__box__ __mt__15`}
        />
      ) : null}
      {value ? (
        <>
          <br />
          <h4>Manage {displayValue}</h4>
          <StructureManagementDataGrid
            isLoading={isLoading}
            rows={structureManagement.rows ?? []}
            columns={structureManagement.columns}
            permissions={getPermissionsForPage(routeKey)}
            dropDownData={structureManagement.dropdownValues}
            postData={updateOrCreateStructureManagement}
            handleDelete={deleteStructureManagement}
            valueToFetch={value}
            allowSelection
            allowAdding={value !== 'entity-country-hfm'}
            allowDeletingFromApi={value !== 'entity-country-hfm'}
            bulkUploadApi={bulkUpload}
            apiCallback={getStructureManagement}
            tableName={structureManagement.tableName}
            uploadTemplateLink={structureManagement.uploadTemplateLink}
            orgStructureLink={structureManagement.orgStructureLink}
            allowBulkUploading={tablesForBulkUpload.indexOf(value) !== -1}
          />
        </>
      ) : null}
    </>
  );
};

function StructureManagement({ routeKey, pageName }) {
  const { getStructureManagementTabs } = useStructureManagement(routeKey);
  const [{ structureManagementList }, dispatch] = useContext(Context);

  const [tabValue, setTabValue] = useState(0);

  const options = structureManagementList ?? [];

  useEffect(async () => {
    await getStructureManagementTabs();
  }, []);

  const handleChange = useCallback(
    (e) => {
      if (e.event !== undefined) {
        setTabValue(e.itemIndex);
      }
    },
    [tabValue]
  );

  return (
    <div className="__settings__container__right__section__ __structure__management__">
      <Tabs
        className="__tabs__content__"
        width={'auto'}
        selectedIndex={tabValue}
        onItemClick={handleChange}
        aria-label="tabs"
      >
        {options.map((obj, i) => {
          return <Item key={`structure-management-tabs-${i}`} render={() => <div className="__font__family__regular">{obj.label}</div>} />;
        })}
      </Tabs>
      {options.map((obj, index) => {
        return tabValue === index ? (
          <RenderCompAndDropDown key={`structure-management-active-tab-${index}`} lists={obj.categories} routeKey={routeKey} />
        ) : null;
      })}
    </div>
  );
}

export default StructureManagement;
