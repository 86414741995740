import React, { useEffect } from 'react';
import Loadable from 'react-loadable';
import CustomSkelton from '../../../../components/Skelton/CustomSkelton';
import BaseLayOut from '../../../../components/Layout/BaseLayOut';
import useEntityVerticalCapability from '../../../../utils/hooks/useEntityVerticalCapability';
import Skelton from '../../../../components/Skelton/defaultSkelton';
const EntityVerticalCapabilityDataGrid = Loadable({
  loader: () => import('./EntityVerticalCapabilityDataGrid'),
  loading: () => <Skelton />
});

export default function EntityVerticalCapability({ routeKey, pageName }) {
  const {
    getAllEntityVerticalCapabilities,
    createUpdateEntityVerticalCapabilities,
    deleteEntityVerticalCapabilities,
    setEntityVerticalCapability,
    entityVerticalCapability,
    permissions,
    isLoading
  } = useEntityVerticalCapability(routeKey);

  useEffect(() => {
    const fn = async () => await getAllEntityVerticalCapabilities();
    fn();

    return () => {
      setEntityVerticalCapability({ rows: [], columns: [], dropdownData: {} });
    };
  }, []);

  useEffect(() => {}, [entityVerticalCapability]);

  return (
    // <CustomSkelton is_loading={isLoading}>
    //   <BaseLayOut pageTitle={pageName ?? 'Entity Vertical Capability'}>
    <div className="__settings__container__right__section__">
      <EntityVerticalCapabilityDataGrid
        rows={entityVerticalCapability?.rows}
        columns={entityVerticalCapability?.columns}
        dropDownData={entityVerticalCapability?.dropdownData}
        isLoading={isLoading}
        postAPI={createUpdateEntityVerticalCapabilities}
        handleDelete={deleteEntityVerticalCapabilities}
        permissions={permissions}
      />
    </div>
    //   </BaseLayOut>
    // </CustomSkelton>
  );
}
