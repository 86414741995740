import React, { useEffect, useState, useContext, Fragment } from 'react';
import Loadable from 'react-loadable';
import 'antd/dist/antd.css';
import { TextBox } from 'devextreme-react/text-box';
import MDTypography from 'components/MDTypography';
import MDBox from 'components/MDBox';
import { Context } from 'utils/context/store/Store';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { DEButton } from 'utils/services/Helpers';
import { useParams } from 'react-router-dom';
import useJobDescriptionPage from 'utils/hooks/useJobDescriptionPage';
import BaseLayOut from 'components/Layout/BaseLayOut';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ToPrint from '../../job-description/ToPrint';
import Skelton from '../../../components/Skelton/defaultSkelton';
const AccordionCustom = Loadable({
  loader: () => import('../../../components/AccordionCustom'),
  loading: () => <Skelton />
});
import '../../job-description/styles.scss';

const JDView = ({ data, title = 'JD View', btnTitle = 'View', isDisabled = false }) => {
  //TODO FEEDBACK: update UI with new design

  const { id, version } = useParams();

  const { getJDById } = useJobDescriptionPage();

  const [{ dataAccess, jdVersions }, dispatch] = useContext(Context);

  const [mainTaskColorBar, setMainTaskColorBar] = useState([]);

  useEffect(async () => {
    if (id && version) {
      await getJDById(id, false, version);
    }
  }, []);

  useEffect(() => {
    updateColorBar();
  }, [jdVersions]);

  const upsert = (array, item, type = null) => {
    let i = null;

    if (type === 'deletedVal') {
      i = array.findIndex((_item) => _item.id === item.id);
    } else {
      i = array.findIndex((_item) => _item.masterJdElementId === item.masterJdElementId);
    }

    if (i > -1) array[i] = item;
    else array.push(item);

    return array;
  };

  const updateColorBar = () => {
    if (jdVersions?.value && jdVersions?.value.length > 0) {
      let updatedColorbarObject = [];
      jdVersions?.value.map((segment) => {
        segment.elements.map((element) => {
          element.jd_values.map((value) => {
            if (segment.mainTask) {
              let cBar = [...mainTaskColorBar];
              updatedColorbarObject = upsert(mainTaskColorBar, {
                masterJdElementId: element.id,
                value: value.elementScore,
                color: element.color,
                showPercentage: value.elementScore != 0 ? true : false,
                textColor: 'black',
                fontSize: 12
              });
            }
          });
        });
      });
    }
  };

  const getJobName = () => {
    return jdVersions.data?.jobName;
  };

  const getLevelLabel = () => {
    // if (jdVersions && jdVersions.data && jdVersions.data.hasOwnProperty('masterLevelId')) {
    //   const filteredData = dataAccess && dataAccess?.levels?.filter(l => l.id === jdVersions.data.masterLevelId)
    //   return filteredData && filteredData.length ? filteredData[0].label : null
    // }
    //
    // return null

    return jdVersions.data?.masterLevelName;
  };

  const getDivisionLabel = () => {
    // if (jdVersions && jdVersions.data && jdVersions.data.hasOwnProperty('masterOrgDivisionId')) {
    //   const filteredData = dataAccess && dataAccess?.divisions?.filter(l => l.id === jdVersions.data.masterOrgDivisionId)
    //   return filteredData && filteredData.length ? filteredData[0].label : null
    // }
    return jdVersions.data?.masterOrgDivisionName;
  };

  const getEntityLabel = () => {
    // if (jdVersions && jdVersions.data.hasOwnProperty('masterOrgEntityId')) {
    //   const filteredData = dataAccess && dataAccess?.Entities?.filter(l => l.id === jdVersions.data.masterOrgEntityId)
    //   return filteredData && filteredData.length ? filteredData[0].label : null
    // }
    //
    // return null
    return jdVersions.data?.masterOrgEntityName
      ? jdVersions.data?.masterOrgEntityName
      : 'All Entities';
  };

  const getCountryLabel = () => {
    // if (jdVersions && jdVersions.data.hasOwnProperty('masterCountryId')) {
    //   const filteredData = dataAccess && dataAccess?.countries?.filter(l => l.id === jdVersions.data.masterCountryId)
    //   return filteredData && filteredData.length ? filteredData[0].label : null
    // }
    //
    // return null

    return jdVersions.data?.masterCountryName
      ? jdVersions.data?.masterCountryName
      : 'All Countries';
  };

  const getVerticalLabel = () => {
    // if (jdVersions && jdVersions.data && jdVersions.data.hasOwnProperty('masterOrgVerticalId')) {
    //   const filteredData = dataAccess && dataAccess?.Verticals?.filter(l => l.masterOrgVerticalId === jdVersions.data.masterOrgVerticalId)
    //   return filteredData && filteredData.length ? filteredData[0].name : null
    // }
    //
    // return null

    return jdVersions.data?.masterOrgVerticalName;
  };

  const getFunctionLabel = () => {
    // if (jdVersions && jdVersions.data && jdVersions.data.hasOwnProperty('masterOrgFunctionId')) {
    //   const filteredData = dataAccess && dataAccess?.functions?.filter(l => l.masterOrgFunctionId === jdVersions.data.masterOrgFunctionId)
    //   return filteredData && filteredData.length ? filteredData[0].name : null
    // }
    //
    // return null

    return jdVersions.data?.masterOrgFunctionName;
  };

  return (
    <div className="__body__section __job__description__ __jd__container__">
      {jdVersions?.data ? (
        <>
          <div className="__heading__section__">
            <h3>{getJobName()}</h3>
          </div>
          <div className="__job__content__section__">
            <div className="__form__container__">
              <div className="__form__boxes__section__">
                <div className="__form__select__boxes__">
                  <TextBox
                    value={`Level: ${getLevelLabel() ?? null}`}
                    disabled={true}
                    valueChangeEvent="input"
                    className={`__input__box__`}
                  />
                </div>
                <div className="__form__select__boxes__">
                  <TextBox
                    value={`Division: ${getDivisionLabel() ?? null}`}
                    disabled={true}
                    valueChangeEvent="input"
                    className={`__input__box__`}
                  />
                </div>
                <div className="__form__select__boxes__">
                  <TextBox
                    value={`Entity: ${getEntityLabel() ?? null}`}
                    disabled={true}
                    valueChangeEvent="input"
                    className={`__input__box__`}
                  />
                </div>
                <div className="__form__select__boxes__">
                  <TextBox
                    value={`Country: ${getCountryLabel() ?? null}`}
                    disabled={true}
                    valueChangeEvent="input"
                    className={`__input__box__`}
                  />
                </div>
                <div className="__form__select__boxes__">
                  <TextBox
                    value={`Vertical: ${getVerticalLabel() ?? null}`}
                    disabled={true}
                    valueChangeEvent="input"
                    className={`__input__box__`}
                  />
                </div>
                <div className="__form__select__boxes__">
                  <TextBox
                    value={`Function: ${getFunctionLabel() ?? null}`}
                    disabled={true}
                    valueChangeEvent="input"
                    className={`__input__box__`}
                  />
                </div>
              </div>
              <div className="__form__accordion__section">
                {jdVersions?.value?.length > 0 ? (
                  jdVersions.value.map((segment, index) => {
                    return (
                      <AccordionCustom
                        title={segment.name}
                        uniqueId={`__job__description__form__sections__${index}`}
                        className="__custom__accordion __create__job__description__ __mt__0"
                        fontSize="h5"
                        key={`__job__description__form__sections__${index}`}
                        isOpened={true}
                      >
                        <div className="__job__description__accordion__content__">
                          {segment.elements.map((element, index) => {
                            return (
                              <div
                                key={`__segments__container__${index}`}
                                className="__segments__container__"
                              >
                                {!element.summary && (
                                  <div className="__segment__summary__">
                                    <h5 style={{ color: element.color }}>
                                      {element.name}
                                      <br />
                                    </h5>
                                  </div>
                                )}
                                <div className={`__segment__status__ __font__family__regular`}>
                                  {element.jd_values.map((value, i) => (
                                    <h5><p
                                      className='__dynamic__html__content'
                                      key={`__segment__status__${i}`}
                                      dangerouslySetInnerHTML={{ __html: value?.value }}
                                    /></h5>
                                  ))}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </AccordionCustom>
                    );
                  })
                ) : (
                  <Skelton />
                )}
              </div>
              {jdVersions?.value?.length > 0 && (
                <div className="__form__action__section__">
                  <div className="__form__buttons__section__">
                    <PDFDownloadLink
                      document={
                        <ToPrint
                          jobName={getJobName()}
                          jdData={jdVersions?.value}
                          division_name={getDivisionLabel()}
                        />
                      }
                      fileName={getJobName()}
                    >
                      <DEButton
                        text="Export to PDF"
                        type="default"
                        className="__default__button__layout __unset__border"
                        stylingMode="contained"
                      />
                    </PDFDownloadLink>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      ) : null}

      {/* TODO:: remove once tested okay */}
      {false && jdVersions?.value && jdVersions?.value.length > 0
        ? jdVersions?.value.map((segment) => {
            return (
              <MDBox py={3}>
                <Card>
                  <MDBox p={3} lineHeight={1}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={4}>
                        <MDTypography variant="h6" fontWeight="medium">
                          {segment.name}
                        </MDTypography>
                      </Grid>
                    </Grid>
                    {segment.elements.map((element) => {
                      return (
                        <MDBox pt={2} lineHeight={1}>
                          <Grid container spacing={2} alignItems="center">
                            {!element.summary ? (
                              <Grid item xs={2.5}>
                                <MDTypography
                                  variant="button"
                                  style={{ color: element.color }}
                                  fontWeight="medium"
                                >
                                  {element.name}
                                </MDTypography>
                              </Grid>
                            ) : null}
                          </Grid>
                          {element.jd_values.map((value) => {
                            return (
                              <MDBox pt={2} lineHeight={1}>
                                <MDTypography variant="button">
                                  <MDBox
                                    pl={3}
                                    dangerouslySetInnerHTML={{ __html: value?.value }}
                                  />
                                </MDTypography>
                              </MDBox>
                            );
                          })}
                        </MDBox>
                      );
                    })}
                  </MDBox>
                </Card>
              </MDBox>
            );
          })
        : null}

      {false && jdVersions?.data && jdVersions?.value?.length > 0 ? (
        <MDBox component="span" m={1} display="flex" justifyContent="flex-end" alignItems="center">
          <MDBox mr={1}>
            <>
              <PDFDownloadLink
                document={
                  <ToPrint
                    jobName={getJobName()}
                    jdData={jdVersions?.value}
                    division_name={getDivisionLabel()}
                  />
                }
                fileName={getJobName()}
              >
                <DEButton
                  text="Export to PDF"
                  disabled={false}
                  stylingMode="contained"
                  type="normal"
                />
              </PDFDownloadLink>
            </>
          </MDBox>
        </MDBox>
      ) : null}
    </div>
  );
};

export default JDView;
