import React, { useEffect } from 'react';
import Loadable from 'react-loadable';
import BaseLayOut from '../../components/Layout/BaseLayOut';
import useExitManagement from '../../utils/hooks/useExitManagement';
import usePagePermissions from '../../utils/hooks/usePagePermissions';
import EmployeeResignation from './EmployeeResignation';
import Skelton from '../../components/Skelton/defaultSkelton';
const ExitManagementDataGrid = Loadable({
  loader: () => import('./DataTable/ExitManagementDataGrid'),
  loading: () => <Skelton />
});

export default function ExitManagement({ routeKey, pageName }) {
  const { getAllExitRecords, createOrUpdateExitRecords, exitDetails, isLoading } =
    useExitManagement(routeKey);
  const { getPermissionsForPage } = usePagePermissions(routeKey);

  useEffect(() => {
    const fn = async () => await getAllExitRecords();
    fn();
  }, []);

  return (
    // <BaseLayOut pageTitle={pageName}>
    <div className="__body__section __base__layout__container __manage__segments__">
      <h3>Exit Management</h3>
      <ExitManagementDataGrid
        allowEditing={true}
        columns={exitDetails?.columns}
        rows={exitDetails?.rows}
        permissions={getPermissionsForPage()}
        apiToPostData={createOrUpdateExitRecords}
        canUserCreate={exitDetails.canUserCreate}
        masterEmployeeDetail={exitDetails.masterEmployeeDetail}
        apiCallBack={getAllExitRecords}
      />
    </div>
    // </BaseLayOut>
  );
}
