import React, { useCallback, useEffect, useState } from 'react';
import Loadable from 'react-loadable';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import { registerAllModules } from 'handsontable/registry';
import Skelton from '../../components/Skelton/defaultSkelton';
const HandsOnTableComponent = Loadable({
  loader: () => import('./components/handsOnTable'),
  loading: () => <Skelton />
});
const ActionButtons = Loadable({
  loader: () => import('./components/actionButtons'),
  loading: () => <Skelton />
});
const TableView = Loadable({
  loader: () => import('./components/tableView'),
  loading: () => <Skelton />
});
import useFinancialForecast from '../../utils/hooks/useFinancialForecast';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import 'handsontable/dist/handsontable.min.css';
import './components/styles.scss';
import { getUserInfo } from '../../utils/services/Helpers';
import QueueDisplay from './components/QueueDisplay';
import usePagePermissions from 'utils/hooks/usePagePermissions';

registerAllModules();

const FinancialForecast = ({ routeKey, pageName }) => {
  const [isFullScreen, setFullScreen] = useState(false);
  const [hotTableRef, setHotTableRef] = useState(null);
  const { getPermissionsForPage } = usePagePermissions(routeKey);
  const hookFinancialForecast = useFinancialForecast();

  const {
    headers,
    renderTable,
    dataSource,
    dropdownData,
    data,
    setData,
    getDropdownData,
    sheetTwo,
    isSheetSubmitted,
    canSubmitSheet,
    canActionSheet,
    canEdit,
    setCanEdit,
    queueMessage,
    setQueueMessage,
    socket,
    sheetEdit,
    getDataForTable,
  } = hookFinancialForecast;

  useEffect(() => {
    const callback = async () => {
      await getDropdownData();
    };
    callback();
  }, []);
  useEffect(() => { }, [sheetEdit, canEdit]);
  useEffect(() => { }, [hotTableRef]);
  useEffect(() => {
    const userId = getUserInfo()?.id;
    const eventName = `handle_lock_unlock_rf_${userId}`;
    const handleSocketEvent = async ({ canEdit: canUserEdit, message, reload, key, chunk }) => {
      setCanEdit(canUserEdit);
      if (!canUserEdit) setQueueMessage(message);
      else {
        setQueueMessage(null);
        if (reload) {
          try {
            await Promise.all([getDropdownData(), getDataForTable()]);
          } catch (error) {
            console.error('Error reloading data:', error);
          }
        }
      }
    };
    // Attach socket event listener
    socket.on(eventName, handleSocketEvent);
    // Cleanup function
    return () => {
      socket.emit('handle-rf-page', { action: true, userId });
      socket.off(eventName, handleSocketEvent); // Remove specific listener
    };
  }, [socket]); // Dependency array includes only socket

  const handsOnTable = useCallback(() => {
    if (sheetTwo?.hasOwnProperty('data')) {
      let comparisonHeader = sheetTwo.headers[0].header.header;
      return (
        <>
          <HandsOnTableComponent
            dataWithIDs={dropdownData?.data}
            setHotTableRef={setHotTableRef}
            permissions={getPermissionsForPage(routeKey)}
            {...hookFinancialForecast}
          />
          <br /> <h2>Comparison sheet</h2>
          <HandsOnTableComponent
            dataWithIDs={dropdownData?.data}
            dataSource={sheetTwo.data}
            headers={comparisonHeader}
            data={data}
            setData={setData}
            alignHeaders={hookFinancialForecast.alignHeaders}
            addClassesToRows={hookFinancialForecast.addClassesToRows}
            toHide={true}
            permissions={getPermissionsForPage(routeKey)}
          />
        </>
      );
    }
    return (
      <>
        {renderTable && dataSource && dataSource?.length ? (
          <HandsOnTableComponent
            collaboration={dropdownData?.collaboration}
            disabled={!canEdit || hookFinancialForecast.version1}
            setHotTableRef={setHotTableRef}
            dataWithIDs={dropdownData?.data}
            permissions={getPermissionsForPage(routeKey)}
            {...hookFinancialForecast}
          />
        ) : (
          <div style={{ textAlign: 'center', padding: '20px', fontSize: '16px', color: '#888' }}>
            No data available to perform this action.
          </div>
        )}
      </>
    );
  }, [
    headers, dataSource, dropdownData, isSheetSubmitted, hotTableRef,
    setHotTableRef, canSubmitSheet, canEdit, queueMessage, sheetEdit,
    renderTable, setCanEdit, canActionSheet
  ]);

  return (
    <div className="__body__section __base__layout__container __financial__forcast__">
      <h3>Financial Forecast</h3>
      <div className="__financial__forcast__section__">
        <div className="__financial__forecast__action__buttons__">
          {
            !canEdit && queueMessage && <QueueDisplay message={queueMessage} />
          }
          <ActionButtons permissions={getPermissionsForPage(routeKey)} hotTableRef={hotTableRef} {...{ ...hookFinancialForecast }} />
        </div>
        {
          hookFinancialForecast.cyclePeriod && hookFinancialForecast.hfm && hookFinancialForecast.businessCategory
          && <TableView {...{ ...hookFinancialForecast }} />
        }

        <div className="__financial__forecast__table__">
          {renderTable && dataSource && dataSource?.length && handsOnTable()}
        </div>
      </div>
      <Popup
        height={'auto'}
        visible={isFullScreen}
        className="__font__family"
        dragEnabled={false}
        onHiding={() => setFullScreen(false)}
        contentRender={() => (
          <ScrollView width="100%" height="100%">
            <div className="__financial__forcast__section__">
              <div className="__financial__forecast__action__buttons__">
                <ActionButtons {...{ ...hookFinancialForecast }} />
              </div>
              <div className="__financial__forecast__table__ __mt__15">
                {renderTable && handsOnTable()}
              </div>
            </div>
          </ScrollView>
        )}
      >
        <ToolbarItem
          render={() => <h2 className="__font__family __mb__0">Financial Forecast</h2>}
          location="center"
          toolbar="top"
        />
      </Popup>

      {/* TODO remove once tested okay */}
      {false && (
        <Modal
          open={isFullScreen}
          onClose={() => setFullScreen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: 'absolute',
              overflowY: 'auto',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '100%',
              height: '100%',
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4
            }}
          >
            <div className="__financial__forcast__section__">
              <div className="__financial__forecast__action__buttons__">
                <ActionButtons {...{ ...hookFinancialForecast }} />
              </div>
              <div className="__financial__forecast__table__ __mt__15">
                {renderTable && handsOnTable()}
              </div>
            </div>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default FinancialForecast;
