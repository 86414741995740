import BaseLayOut from '../Layout/BaseLayOut';
import MDTypography from '../MDTypography';
import MDBox from '../MDBox';

export default function NotFound({
  title = '404!',
  text = 'Oops The page you are looking is not found.'
}) {
  return (
    <>
      <div className="__body__section __manage__jobs__">
        <div className="__font__family__regular __base__layout__container">
          <h1>{title}</h1>
          <h5>{text}</h5>
        </div>
      </div>
      {/* TODO: remove once tested okay */}
      {false && (
        <BaseLayOut pageTitle={title}>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h2" fontWeight="medium">
              {text}
            </MDTypography>
          </MDBox>
        </BaseLayOut>
      )}
    </>
  );
}
