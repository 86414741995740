import React, { useContext, useEffect, useState } from 'react';
import Loadable from 'react-loadable';
import { Context } from 'utils/context/store/Store';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../../examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
import Card from '@mui/material/Card';
import usePagePermissions from 'utils/hooks/usePagePermissions';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import useBulkUpload from 'utils/hooks/useBulkUpload';
import useStructureManagement from '../../../utils/hooks/useStructureManagement';
import Skelton from '../../../components/Skelton/defaultSkelton';
const StructureManagementDataGrid = Loadable({
  loader: () => import('../../structure-management/DataTable/StructureManagementDataGrid'),
  loading: () => <Skelton />
});

let value = 'department';
function StructureManagement({ routeKey, pageName }) {
  const { bulkUpload } = useBulkUpload(routeKey);
  const {
    getStructureManagement,
    deleteStructureManagement,
    updateOrCreateStructureManagement,
    isLoading
  } = useStructureManagement(routeKey);
  const [{ structureManagement }, dispatch] = useContext(Context);
  const { getPermissionsForPage } = usePagePermissions(routeKey);
  const tablesForBulkUpload = ['department'];

  useEffect(async () => {
    await getStructureManagement(value);
  }, []);

  useEffect(() => {}, [structureManagement]);

  return (
    <div className="__body__section __base__layout__container __manage__values__">
      <h3>Manage Agency Practice</h3>
      <StructureManagementDataGrid
        isLoading={isLoading}
        rows={structureManagement.rows ?? []}
        columns={structureManagement.columns}
        permissions={getPermissionsForPage(routeKey)}
        dropDownData={structureManagement.dropdownValues}
        postData={updateOrCreateStructureManagement}
        handleDelete={deleteStructureManagement}
        valueToFetch={value}
        allowSelection
        allowAdding={value !== 'entity-country-hfm'}
        allowDeletingFromApi={value !== 'entity-country-hfm'}
        bulkUploadApi={bulkUpload}
        apiCallback={getStructureManagement}
        tableName={structureManagement.tableName}
        uploadTemplateLink={structureManagement.uploadTemplateLink}
        orgStructureLink={structureManagement.orgStructureLink}
        allowBulkUploading={tablesForBulkUpload.indexOf(value) !== -1}
      />
    </div>
  );
}

export default StructureManagement;
