import React, { useEffect } from 'react';
import Loadable from 'react-loadable';
import BaseLayOut from '../../components/Layout/BaseLayOut';
import CustomSkelton from '../../components/Skelton/CustomSkelton';
import useEmailDeliveryStatusHub from '../../utils/hooks/useEmailDeliveryStatusHub';
import Skelton from '../../components/Skelton/defaultSkelton';
const EmailDeliveryStatusHubDataGrid = Loadable({
  loader: () => import('./datagrid/EmailDeliveyStatusHubDataGrid'),
  loading: () => <Skelton />
});

const EmailDeliveryStatusHub = ({ pageName, routeKey }) => {
  const { isLoading, getAllEmailDeliveryStatusHub, stats } = useEmailDeliveryStatusHub(routeKey);

  useEffect(() => {
    const fn = async () => await getAllEmailDeliveryStatusHub();
    fn();
  }, []);

  return (
    <div className="__settings__container__right__section__">
      <EmailDeliveryStatusHubDataGrid
        isLoading={isLoading}
        rows={stats?.rows ?? []}
        columns={stats?.columns ?? []}
      />
    </div>
  );
};

export default EmailDeliveryStatusHub;
