import request from '../services/Http';
import { useContext } from 'react';
import { Context } from '../context/store/Store';
import {
  addKeyAndIndexFieldToGenericArrayOfObjects,
  getPageProperties,
  getTableColumns
} from '../services/Helpers';
import { SET_IS_LOADING, SET_CLIENT_VITALS } from '../context/store/Constants';
import { useIsLoading } from './useIsLoading';

export default function useManageClientVitals(routeKey) {
  const [{ clientVitals, pagesAndPermissions }, dispatch] = useContext(Context);
  const { isLoading, setIsLoading } = useIsLoading();

  async function fetchClientVitals(dropdownValue = null, viewTabValue = 0) {
    setIsLoading(true);
    dispatch({ type: SET_IS_LOADING, payload: true });

    try {
      const res = await request.get('cv');
      if (res) {
        let columns = [];
        if (res.data && res.data.dropdownValues && res.data.dropdownValues.masterCvCategoryId) {
          columns = fillClientVitalsColumns(res.data.dropdownValues.masterCvCategoryId);
        }
        let rows = res.data.data;
        let dropdownValues = res.data.dropdownValues;

        if (clientVitals && clientVitals.hasOwnProperty('apiDelete') && clientVitals.apiDelete) {
          rows = [...clientVitals.rows, ...rows];
        }

        const dataTableData = {
          columns: columns,
          rows: addKeyAndIndexFieldToGenericArrayOfObjects(rows),
          dropdownValues: dropdownValues,
          apiDelete: false,
          dropdownValue,
          tabValue: viewTabValue
        };

        dispatch({
          type: SET_CLIENT_VITALS,
          payload: dataTableData
        });
      }
    } catch (e) {
    } finally {
      setIsLoading(false);
      dispatch({ type: SET_IS_LOADING, payload: false });
    }
  }

  function fillClientVitalsColumns(data) {
    let columns = [
      {
        title: 'Status',
        dataIndex: 'disable',
        type: 'button',
        width: '10px',
        editable: false,
        ellipsis: false,
        required: false,
        is_visible: true,
        is_sortable: false,
        is_searchable: false
      },
      {
        title: 'Agency/Practice',
        dataIndex: 'masterDepartmentId',
        type: 'int',
        width: '',
        editable: false,
        ellipsis: false,
        required: false,
        is_visible: true,
        is_sortable: true,
        is_searchable: true
      },
      {
        title: 'Client',
        dataIndex: 'masterClientId',
        type: 'int',
        width: '',
        editable: false,
        ellipsis: false,
        required: false,
        is_visible: true,
        is_sortable: true,
        is_searchable: true
      }
    ];

    data.map((item) => {
      columns.push({
        title: item.name,
        dataIndex: item.name,
        type: 'data',
        width: '',
        editable: false,
        ellipsis: false,
        required: false,
        is_visible: true,
        is_sortable: true,
        is_searchable: true
      });
    });

    return columns;
  }

  return { fetchClientVitals, isLoading };
}
