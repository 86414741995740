import React, { useContext, useEffect } from 'react';
import Loadable from 'react-loadable';
import usePagePermissions from '../../utils/hooks/usePagePermissions';
import BaseLayOut from '../../components/Layout/BaseLayOut';
import useUsers from '../../utils/hooks/useUsers';
import { Context } from '../../utils/context/store/Store';
import useBulkUpload from 'utils/hooks/useBulkUpload';
import Skelton from '../../components/Skelton/defaultSkelton';
const UserDataGrid = Loadable({
  loader: () => import('./datatable/UserDataGrid'),
  loading: () => <Skelton />
});

export default function User({ routeKey, pageName }) {
  const { getPermissionsForPage } = usePagePermissions(routeKey);
  const { fetchUsers, updateOrCreateAccess, deleteUsers, isLoading } = useUsers(routeKey);
  const { bulkUpload } = useBulkUpload(routeKey);
  const [{ users }, dispatch] = useContext(Context);

  useEffect(async () => {
    await fetchUsers();
  }, []);

  return (
    // <BaseLayOut pageTitle={pageName ?? 'Manage Users and Data Security'}>
    <div className="__settings__container__right__section__">
      <UserDataGrid
        columns={users.columns}
        loading={isLoading}
        orgRows={users.orgRows}
        routeKey={routeKey}
        rows={users.rows}
        objToAdd={users.newRowObject}
        isForUser={true}
        postData={updateOrCreateAccess}
        fetchFromApi={fetchUsers}
        allowSelection={true}
        handleDelete={deleteUsers}
        permissions={getPermissionsForPage()}
        dropDownValues={users.dropdownValues}
        bulkUploadApi={bulkUpload}
        apiCallback={fetchUsers}
        tableName={users.tableName}
        uploadTemplateLink={users.uploadTemplateLink}
        orgStructureLink={users.orgStructureLink}
        allowBulkUploading={true}
      />
    </div>
    // </BaseLayOut>
  );
}
