import React, { useContext, useEffect, useState, useCallback } from 'react';
import Loadable from 'react-loadable';
import { Context } from 'utils/context/store/Store';
import { SelectBox } from 'devextreme-react/select-box';
import useAttributeManagement from 'utils/hooks/useAttributeManagement';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
import Card from '@mui/material/Card';
import usePagePermissions from 'utils/hooks/usePagePermissions';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import Select from 'react-select';
import { Grid } from '@mui/material';
import { selectCustomStyles, a11yProps } from '../../utils/services/Helpers';
import useBulkUpload from 'utils/hooks/useBulkUpload';
import Skelton from '../../components/Skelton/defaultSkelton';
const AttributeManagementDataGrid = Loadable({
  loader: () => import('./DataTable/AttributeManagementDataGrid'),
  loading: () => <Skelton />
});

function AttributeManagement({ routeKey, pageName }) {
  const {
    getAttributeManagementList,
    getAttributeManagement,
    deleteAttributeManagement,
    updateOrCreateAttributeManagement,
    isLoading
  } = useAttributeManagement(routeKey);
  const { bulkUpload } = useBulkUpload(routeKey);
  const [{ attributeManagement, attributeManagementList }, dispatch] = useContext(Context);
  const { getPermissionsForPage } = usePagePermissions(routeKey);

  const [dValue, setDValue] = useState(null);
  const [value, setValue] = useState(null);
  const [displayValue, setDisplayValue] = useState(null);

  const tablesForBulkUpload = [
    'client',
    'budget-status',
    'gig',
    'management-type',
    'recruitment-status',
    'eos-type',
    'eos-reason'
  ];

  useEffect(async () => {
    await getAttributeManagementList();
    if (value !== null) await getAttributeManagement(value);
  }, []);

  useEffect(async () => {
    if (value !== null) await getAttributeManagement(value);
  }, [value]);

  const selectBoxHandle = useCallback(
    (e) => {
      if (e.event !== undefined) {
        setValue(e.value);
        const indexInArray = attributeManagementList?.findIndex(
          (eFindIndex) => eFindIndex.value === e.value
        );
        indexInArray !== -1 && setDisplayValue(attributeManagementList?.[indexInArray].label);
      }
    },
    [attributeManagementList, value, displayValue, setValue, setDisplayValue]
  );

  return (
    <div className="__settings__container__right__section__">
      <SelectBox
        width={300}
        placeholder={'Please select option... '}
        dataSource={attributeManagementList}
        value={value ?? null}
        searchEnabled={true}
        showClearButton={true}
        valueExpr="value"
        displayExpr="label"
        onValueChanged={(e) => selectBoxHandle(e)}
        className={`__select__box__ __mt__15`}
      />
      {value ? (
        <>
          <br />
          <h4>Manage {displayValue}</h4>
          <AttributeManagementDataGrid
            isLoading={isLoading}
            rows={attributeManagement.rows ?? []}
            columns={attributeManagement.columns}
            permissions={getPermissionsForPage(routeKey)}
            dropDownData={attributeManagement.dropdownValues}
            postData={updateOrCreateAttributeManagement}
            handleDelete={deleteAttributeManagement}
            valueToFetch={value}
            allowSelection={true}
            allowAdding={true}
            allowDeletingFromApi={true}
            bulkUploadApi={bulkUpload}
            apiCallback={getAttributeManagement}
            tableName={attributeManagement.tableName}
            uploadTemplateLink={attributeManagement.uploadTemplateLink}
            orgStructureLink={attributeManagement.orgStructureLink}
            allowBulkUploading={tablesForBulkUpload.indexOf(value) !== -1}
          />
        </>
      ) : null}
    </div>
  );
}

export default AttributeManagement;
