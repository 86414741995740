import React, { useEffect, memo } from 'react';
import BaseLayOut from '../../components/Layout/BaseLayOut';
import useDelegatedAccess from '../../utils/hooks/useDelegatedAccess';
import CustomSkelton from '../../components/Skelton/CustomSkelton';
import DelegatedAccessDataGrid from './DataGrid/DelegatedAccessDataGrid';
import usePagePermissions from '../../utils/hooks/usePagePermissions';

const DelegatedAccess = memo(({ pageName, routeKey }) => {
  const {
    delegatedAccesses,
    getDelegatedAccess,
    isLoading,
    createOrUpdateDelegatedAccess,
    deleteDelegatedAccess
  } = useDelegatedAccess(routeKey);

  const { getPermissionsForPage } = usePagePermissions(routeKey);

  useEffect(() => {
    const fn = async () => await getDelegatedAccess();
    fn();
  }, []);

  return (
    // <CustomSkelton is_loading={isLoading}>
    //   <BaseLayOut pageTitle={pageName ?? 'DelegatedAccess'}>
    <div className="__body__section __base__layout__container __manage__segments__">
      <h3>Delegate Access</h3>
      <DelegatedAccessDataGrid
        rows={delegatedAccesses?.rows ?? []}
        columns={delegatedAccesses?.columns ?? []}
        dropDownData={delegatedAccesses?.dropDownData ?? {}}
        isLoading={isLoading}
        permissions={getPermissionsForPage(routeKey)}
        postAPI={createOrUpdateDelegatedAccess}
        handleDelete={deleteDelegatedAccess}
      />
    </div>
    //   </BaseLayOut>
    // </CustomSkelton>
  );
});

export default DelegatedAccess;
