import React, { useEffect } from 'react';
import Loadable from 'react-loadable';
import BaseLayOut from '../../../../components/Layout/BaseLayOut';
import CustomSkelton from '../../../../components/Skelton/CustomSkelton';
import useTransactionCapability from '../../../../utils/hooks/useTransactionCapability';
import Skelton from '../../../../components/Skelton/defaultSkelton';
const TransactionCapabilityDataGrid = Loadable({
  loader: () => import('./TransactionCapabilityDataGrid'),
  loading: () => <Skelton />
});

export default function TransactionCapability({ routeKey, pageName }) {
  const {
    getAllTransactionCapabilities,
    createUpdateTransactionCapabilities,
    deleteTransactionCapabilities,
    transactionCapability,
    setTransactionCapability,
    permissions,
    isLoading
  } = useTransactionCapability(routeKey);

  useEffect(() => {
    const fn = async () => await getAllTransactionCapabilities();
    fn();
    return () => {
      setTransactionCapability({ rows: [], columns: [], dropdownData: {}, customColumns: [] });
    };
  }, []);

  return (
    // <CustomSkelton is_loading={isLoading}>
    //   <BaseLayOut pageTitle={pageName ?? 'Transaction Capability'}>
    <div className="__settings__container__right__section__">
      <TransactionCapabilityDataGrid
        rows={transactionCapability?.rows}
        columns={transactionCapability?.columns}
        customColumns={transactionCapability?.customColumns}
        dropDownData={transactionCapability?.dropdownData}
        isLoading={isLoading}
        postAPI={createUpdateTransactionCapabilities}
        handleDelete={deleteTransactionCapabilities}
        permissions={permissions}
        allowAdding={false}
        allowSubmit={false}
      />
    </div>
    //   </BaseLayOut>
    // </CustomSkelton>
  );
}
