import React, { useContext, useEffect, useState, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import Loadable from 'react-loadable';
import { Context } from 'utils/context/store/Store';
import useJobs from '../../utils/hooks/useJobs';
import usePagePermissions from '../../utils/hooks/usePagePermissions';
import useColumnPreferences from '../../utils/hooks/useColumnPreferences';
import Skelton from '../../components/Skelton/defaultSkelton';
const ColumnPreferences = Loadable({
  loader: () => import('../../components/ColumnPreferences'),
  loading: () => <></>
});
const ManageJobsDataGrid = Loadable({
  loader: () => import('./components/DataTable/ManageJobsDataGrid'),
  loading: () => <Skelton />
});
const BlackSectionController = Loadable({
  loader: () => import('../../components/BlackSectionController'),
  loading: () => <Skelton />
});
import { SET_MANAGE_JOBS, SET_COLUMN_PREFERENCES } from '../../utils/context/store/Constants';
import { getUserInfo } from '../../utils/services/Helpers';
import { SocketContext } from '../../utils/context/socketContext';

const ManageJobs = memo(({ routeKey, pageName }) => {
  const [, dispatch] = useContext(Context);
  const { getJobs, setJobsData, deleteJobs, approveOrRejectJobs, isLoading, hasMore, manageJobs } =
    useJobs(routeKey);
  const { getPermissionsForPage } = usePagePermissions(routeKey);
  const {
    fetchColumnPreferences,
    setColumnPreferencesData,
    checkColumnPreferencesAndUpdateTableColumns,
    storeColumnPreferences,
    setColumnPreferences
  } = useColumnPreferences();
  const [dataSource, setDataSource] = useState([]);
  const [isColumnsPreferenceUpdated, setIsColumnsPreferenceUpdated] = useState(false);
  const [isClearFilters, setIsClearFilters] = useState(false);
  const navigate = useNavigate();
  const socket = useContext(SocketContext);

  useEffect(() => {
    // const initializeData = async () => {
    //   await fetchColumnPreferences(routeKey, pageName, 1);
    //   await getJobs();
    // };
    socket.emit('data_required', {
      channelName: `user_${getUserInfo()?.id}_manage_jobs_column_preferences`,
      For: 'ManageJobsColumnPreferences',
      userId: getUserInfo()?.id,
      routeKey,
      pageName
    });
    socket.on(`user_${getUserInfo()?.id}_manage_jobs_column_preferences`, (columns) => {
      socket.emit('data_required', {
        channelName: `user_${getUserInfo()?.id}_manage_jobs`,
        For: 'ManageJobs',
        userId: getUserInfo()?.id
      });
      socket.on(`user_${getUserInfo()?.id}_manage_jobs`, (jobs) => {
        setColumnPreferencesData(columns);
        setJobsData(jobs);
      });
    });
    // initializeData();

    return () => {
      socket.off('data_required')
      socket.off(`user_${getUserInfo()?.id}_manage_jobs_column_preferences`)
      setDataSource([]);
      setIsColumnsPreferenceUpdated(false);
      dispatch({ type: SET_MANAGE_JOBS, payload: {} });
      dispatch({ type: SET_COLUMN_PREFERENCES, payload: [] });
    };
  }, []);

  useEffect(() => {
    if (Object.keys(manageJobs).length > 0) {
      setDataSource(manageJobs.rows || []);
    }
  }, [manageJobs?.rows]);

  useEffect(() => {
    if (manageJobs?.columns && !isColumnsPreferenceUpdated) {
      checkColumnPreferencesAndUpdateTableColumns(manageJobs);
      setIsColumnsPreferenceUpdated(true);
    }
  }, [isColumnsPreferenceUpdated, manageJobs]);

  const handleActionButtonText = () => {
    navigate('/views/job-description');
  };

  const updateDataGridTableColumns = (columnsList) => {
    setIsColumnsPreferenceUpdated(false);
    storeColumnPreferences(routeKey, pageName, columnsList, 1);
  };

  const handleClearDataGridColumnFilters = () => {
    setIsClearFilters(true);
  };

  return (
    <div className="__body__section __manage__jobs__">
      <div className="__manage__jobs__container__">
        <BlackSectionController>
          <div className="__intro__section__title__description__">
            <h1 className="__primary__color">Manage Jobs</h1>
            <ColumnPreferences
              actionButtonText="Create Job"
              handleActionButtonText={handleActionButtonText}
              updateDataGridTableColumns={updateDataGridTableColumns}
              handleClearDataGridColumnFilters={handleClearDataGridColumnFilters}
              setColumnPreferences={setColumnPreferences}
            />
          </div>
        </BlackSectionController>
      </div>
      {manageJobs?.columns?.length > 0 ? (
        <ManageJobsDataGrid
          isLoading={isLoading}
          columns={manageJobs?.columns || []}
          rows={dataSource}
          fetchFromApi={getJobs}
          handleDelete={deleteJobs}
          isDeleteable
          permissions={getPermissionsForPage(routeKey)}
          handleApproveOrReject={approveOrRejectJobs}
          hasMore={hasMore}
          isClearFilters={isClearFilters}
          setIsClearFilters={setIsClearFilters}
        />
      ) : (
        <Skelton />
      )}
    </div>
  );
});

export default ManageJobs;
