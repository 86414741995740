import { useMemo, useState, useEffect, useContext, memo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import Loadable from 'react-loadable';
import { useHeadcountManagement } from './hooks/useHeadcountManagement';
import usePagePermissions from 'utils/hooks/usePagePermissions';
import { LEAVER_HEADCOUNT_ID } from './utils/constants';
import './manage-headcount.scss';
import BaseLayOut from 'components/Layout/BaseLayOut';
import { isObjectEmpty } from 'utils/services/Helpers';
import MDTypography from 'components/MDTypography';
import { SET_HC_REQUEST } from '../../utils/context/store/Constants';
import { Context } from '../../utils/context/store/Store';
import Skelton from '../../components/Skelton/defaultSkelton';
const ToggleGroupItems = Loadable({
  loader: () => import('./components/ToggleGroupItems'),
  loading: () => <Skelton />
});
const HcLeaverDataGrid = Loadable({
  loader: () => import('./components/HcLeaverDataGrid'),
  loading: () => <Skelton />
});
const HcDataGrid = Loadable({
  loader: () => import('./components/HcDataGrid'),
  loading: () => <Skelton />
});

const ManageHeadCount = memo(({ routeKey, pageName, masterPanel = false }) => {
  const [{ hcRequestType }, dispatch] = useContext(Context);
  const { id, requestId, headcountTypeId, notificationId } = useParams();
  const { getPermissionsForPage } = usePagePermissions(routeKey);

  const [dgData, setDgData] = useState([]);

  useEffect(() => {

    return () => {
      dispatch({
        type: SET_HC_REQUEST,
        payload: {}
      });
    };
  }, []);

  const {
    isLoading,
    selectedRequestType,
    selectedHeadcountType,
    requestTypeList,
    headcountTypeList,
    isRequestTypeDisabled,
    isHeadcountTypeDisabled,
    onRequestTypeChange,
    onHeadcountChange,
    headcountRequest,
    clonedHeadcounts,
    internalEmpData,
    getHeadcountRequest,
    hcLeaver
  } = useHeadcountManagement(routeKey, id, requestId, headcountTypeId, notificationId);

  const allowAddingCondition = useMemo(() => {
    if (headcountRequest?.isDraft) return true;
    if (!isObjectEmpty(clonedHeadcounts)) return true;
    if (
      internalEmpData &&
      typeof internalEmpData === 'object' &&
      Object.keys(internalEmpData).length &&
      !requestId
    )
      return false;
    return !requestId;
  }, [headcountRequest, clonedHeadcounts, internalEmpData, requestId]);

  const showButtonCondition = useMemo(() => {
    return (
      (headcountRequest &&
        (!headcountRequest.canAction || headcountRequest.showButtonToCreatorAfterApproval)) ||
      selectedHeadcountType === 4
    );
  }, [headcountRequest, selectedHeadcountType]);

  const tableRows = useMemo(() => {
    if (!isObjectEmpty(clonedHeadcounts)) {
      return clonedHeadcounts.rows;
    }

    if (headcountRequest?.rows) {
      return headcountRequest.rows;
    }

    return [];
  }, [clonedHeadcounts, headcountRequest]);

  const leaverTableRows = useMemo(() => {
    if (!isObjectEmpty(clonedHeadcounts)) {
      return clonedHeadcounts.rows;
    }

    if (hcLeaver?.rows) {
      return hcLeaver.rows;
    }

    return [];
  }, [clonedHeadcounts, hcLeaver]);

  const toggleGroupItems = useCallback(
    (title, itemsType, checkedIdVal, onHandleChange, isDisabled) => (
      <ToggleGroupItems
        disabled={isDisabled}
        title={title}
        items={itemsType}
        checkedId={checkedIdVal}
        onChange={onHandleChange}
      />
    ),
    []
  );

  return (
    <div className="__body__section __base__layout__container __manage__headcount__ __create__hc">
      <h3>Create Headcount</h3>
      <div className="manage-headcount">
        <div className="manage-headcount__toggle-group-container">
          {/* <ToggleGroupItems
            title={'Request Type'}
            items={requestTypeList}
            checkedId={selectedRequestType}
            disabled={Boolean(isRequestTypeDisabled) || (dgData && dgData.length)}
            onChange={onRequestTypeChange}
          />
          <ToggleGroupItems
            title={'Headcount Type'}
            items={headcountTypeList}
            disabled={Boolean(isHeadcountTypeDisabled) || (dgData && dgData.length)}
            checkedId={selectedHeadcountType}
            onChange={onHeadcountChange}
          /> */}
          {toggleGroupItems(
            'Request Type',
            requestTypeList,
            selectedRequestType,
            onRequestTypeChange,
            Boolean(isRequestTypeDisabled) || dgData?.length
          )}
          {toggleGroupItems(
            'Headcount Type',
            headcountTypeList,
            selectedHeadcountType,
            onHeadcountChange,
            Boolean(isHeadcountTypeDisabled) || dgData?.length
          )}
        </div>

        {internalEmpData ? (
          <>
            <h4>Internal Mobility Headcount</h4>
            <div className="__font__family__regular __data__grid__container __data__grid__with__search">
              <HcDataGrid
                columns={
                  headcountRequest && headcountRequest.columns ? headcountRequest.columns : []
                }
                rows={internalEmpData?.orgHc ?? null}
                dropDownData={headcountRequest?.dropdownValues || {}}
                isLoading={isLoading}
                disableSubmitButton
                allowUpdating={false}
                showActionColumn={false}
                allowAdding={false}
                showButton
                allowSelection={false}
                allowDeleting={false}
                isForInternalMobility={true}
              />
            </div>
          </>
        ) : null}

        {selectedRequestType &&
        selectedRequestType !== LEAVER_HEADCOUNT_ID &&
        selectedHeadcountType ? (
          <div className="__font__family__regular __data__grid__container __data__grid__with__search">
            <HcDataGrid
              globalState={headcountRequest ?? {}}
              routeKey={routeKey}
              orgColumns={headcountRequest?.orgColumns}
              columns={headcountRequest?.columns || []}
              isDraft={headcountRequest?.isDraft}
              groupId={id}
              permissions={getPermissionsForPage(routeKey)}
              rows={tableRows}
              dropDownData={headcountRequest?.dropdownValues || {}}
              isLoading={isLoading}
              masterRequestTypeId={selectedRequestType}
              masterHeadcountTypeId={selectedHeadcountType}
              allowUpdating={true}
              internalEmpData={internalEmpData}
              allowAdding={allowAddingCondition}
              showButton={showButtonCondition}
              allowSelection={headcountRequest?.canAction}
              appFields={headcountRequest?.appFields || []}
              isInternal={!!internalEmpData}
              allowDeleting={headcountRequest && !headcountRequest.canAction}
              apiCallBack={async () => await getHeadcountRequest(false, id)}
              isCloned={!isObjectEmpty(clonedHeadcounts)}
              setDgData={setDgData}
            />
          </div>
        ) : null}

        {!masterPanel && selectedRequestType === LEAVER_HEADCOUNT_ID ? (
          <div className="__font__family__regular __data__grid__container __data__grid__with__search">
            <HcLeaverDataGrid
              columns={hcLeaver?.columns || []}
              rows={leaverTableRows}
              dropDownData={hcLeaver?.dropdownValues || {}}
              isLoading={isLoading}
              masterRequestTypeId={selectedRequestType}
              routeKey={routeKey}
              permissions={getPermissionsForPage(routeKey)}
              allowAdding={hcLeaver?.isDraft ? true : !id}
              showButton={!id}
              allowSelection={!!id}
              isCloned={!isObjectEmpty(clonedHeadcounts)}
              isDraft={hcLeaver?.isDraft}
              groupId={id}
              setDgData={setDgData}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
});

export default ManageHeadCount;
