import React, { useContext, useEffect } from 'react';
import Loadable from 'react-loadable';
import usePagePermissions from 'utils/hooks/usePagePermissions';
import BaseLayOut from '../../components/Layout/BaseLayOut';
import useManageDepartmentClients from '../../utils/hooks/useManageDepartmentClients';
import { Context } from 'utils/context/store/Store';
import useBulkUpload from 'utils/hooks/useBulkUpload';
import Skelton from '../../components/Skelton/defaultSkelton';
const ManageEntityClientsGrid = Loadable({
  loader: () => import('./components/ManageDepartmentClientsGrid'),
  loading: () => <Skelton />
});

function ManageEntityClients({ routeKey, pageName }) {
  const { getPermissionsForPage } = usePagePermissions(routeKey);
  const { getEntityClients, updateOrCreateEntityClients, deleteEntityClients, isLoading } =
    useManageDepartmentClients(routeKey);
  const { bulkUpload } = useBulkUpload(routeKey);
  const [{ entityClients }] = useContext(Context);

  const getLights = async () => {
    await getEntityClients();
  };

  useEffect(() => {
    getLights();
  }, []);

  return (
    // <BaseLayOut pageTitle={pageName || "Manage Entity Clients"}>
    <div className="__body__section __base__layout__container __manage__values__">
      <h3>Manage Agency Practices Clients</h3>
      <ManageEntityClientsGrid
        isLoading={isLoading}
        rows={entityClients.rows ?? []}
        columns={entityClients.columns}
        permissions={getPermissionsForPage(routeKey)}
        dropDownData={entityClients.dropdownValues}
        postData={updateOrCreateEntityClients}
        handleDelete={deleteEntityClients}
        allowSelection
        allowAdding={true}
        allowDeletingFromApi={true}
        allowBulkUploading
        bulkUploadApi={bulkUpload}
        apiCallback={getEntityClients}
        tableName={entityClients.tableName}
        uploadTemplateLink={entityClients.uploadTemplateLink}
        orgStructureLink={entityClients.orgStructureLink}
      />
    </div>
    // </BaseLayOut>
  );
}

export default ManageEntityClients;
