import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Loadable from 'react-loadable';
import { Context } from '../../utils/context/store/Store';
import BaseLayOut from '../../components/Layout/BaseLayOut';
import usePagePermissions from '../../utils/hooks/usePagePermissions';
import useDataAccess from '../../utils/hooks/useDataAccess';
import useHcRequestType from '../../utils/hooks/useHcRequestType';
import useColumnPreferences from '../../utils/hooks/useColumnPreferences';
import { getUserInfo, a11yProps } from '../../utils/services/Helpers';
import {
  SET_GROUPS,
  SET_All_HEADCOUNTS,
  SET_IS_LOADING
} from '../../utils/context/store/Constants';
import MDBox from 'components/MDBox';
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
import Card from '@mui/material/Card';
import loadingGif from 'assets/images/gifs/loading.gif';
import { Image } from 'antd';
import Tabs, { Item } from 'devextreme-react/tabs';
import { Switch } from 'devextreme-react/switch';
import './css/all-hc.scss';
import useManageTransfers from '../../utils/hooks/useManageTransfers';
import Skelton from '../../components/Skelton/defaultSkelton';
const TabPanel = Loadable({
  loader: () => import('../../components/TabPanel'),
  loading: () => <Skelton />
});
const ColumnPreferences = Loadable({
  loader: () => import('../../components/ColumnPreferences'),
  loading: () => <Skelton />
});
const BlackSectionController = Loadable({
  loader: () => import('../../components/BlackSectionController'),
  loading: () => <Skelton />
});
const GroupsDataGrid = Loadable({
  loader: () => import('../../components/Datagrid/GroupsDataGrid'),
  loading: () => <Skelton />
});
const AllTransfersDataGrid = Loadable({
  loader: () => import('./Datagrid/AllTransfersDataGrid'),
  loading: () => <Skelton />
});

export default function ManageTransfers({ routeKey, pageName }) {
  const [{ groups, allTransfers, isLoading, dataAccess }, dispatch] = useContext(Context);
  const { getPermissionsForPage } = usePagePermissions(routeKey);
  const permissions = getPermissionsForPage();
  const { getAllTransferGroups, getAllTransfers, hasMore } = useManageTransfers(routeKey);
  const { getUserDataAccessByModule } = useDataAccess(routeKey);
  const { getHcRequestType } = useHcRequestType(routeKey);
  const [tabValue, setTabValue] = useState(0);
  const [pageView, setPageView] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [allHcDs, setAllHcDs] = useState([]);
  const [requestIdFilter, setRequestIdToFilter] = useState(null);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const navigate = useNavigate();
  const search = useLocation().search;
  const rId = new URLSearchParams(search).get('rId') ?? null;
  const dgId = new URLSearchParams(search).get('dg') ?? null;
  const transactionType = new URLSearchParams(search).get('transactionType') ?? null;
  const {
    fetchColumnPreferences,
    setColumnPreferencesData,
    checkColumnPreferencesAndUpdateTableColumns,
    storeColumnPreferences,
    setColumnPreferences
  } = useColumnPreferences();

  useEffect(() => {
    dispatch({ type: SET_GROUPS, payload: {} });

    // cleanup on unmount
    return () => {
      dispatch({ type: SET_GROUPS, payload: {} });
      dispatch({ type: SET_All_HEADCOUNTS, payload: {} });
    };
  }, []);

  useEffect(() => {
    const fetchData = async (transactionType = null) => {
      dispatch({ type: SET_IS_LOADING, payload: true });

      try {
        await Promise.all([
          getAllTransfers(transactionType),
          getUserDataAccessByModule(getUserInfo().id, 7)
        ]);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        dispatch({ type: SET_IS_LOADING, payload: false });
      }
    };

    setSelectedStatuses([]);
    if (
      rId !== null &&
      rId !== 'null' &&
      rId !== undefined &&
      transactionType !== null &&
      transactionType !== 'null' &&
      transactionType !== undefined
    ) {
      setTabValue(0);
      setRequestIdToFilter(parseInt(rId));
      fetchData();
    } else if (
      transactionType !== null &&
      transactionType !== 'null' &&
      transactionType !== undefined
    ) {
      fetchData(transactionType);
    } else {
      if (tabValue === 0) {
        fetchData('transaction_transfer');
      } else if (tabValue === 1) {
        getAllTransferGroups();
      }
      // TODO:: remove once tested okay
      // if (!pageView && !tabValue) {
      //   fetchData('transaction_transfer');
      // } else if (!pageView && tabValue) {
      //   fetchData('transaction_transfer');
      // } else if (pageView) {
      //   getAllTransferGroups();
      // }
    }
  }, [tabValue, pageView]);

  useEffect(() => {
    setDataSource(groups?.rows);
  }, [groups]);

  useEffect(() => {
    setAllHcDs(allTransfers?.rows);
  }, [allTransfers]);

  useEffect(() => {
    let data = allTransfers?.rows;

    if (selectedStatuses.length) {
      data = filterByActiveStatus(selectedStatuses, data);
    }

    setAllHcDs(data);
  }, [selectedStatuses, allTransfers]);

  // TODO:: remove once tested okay
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    if (!pageView && (newValue !== 1 || newValue !== 0)) setRequestIdToFilter(null);
    if (rId)
      navigate(`/views/manage-supervisors${dgId !== null ? `?dg=${dgId}` : ''}`, { replace: true });
  };

  const handleLegendClicked = useCallback(
    (filter) => {
      if (selectedStatuses.includes(filter.value)) {
        setSelectedStatuses((prevValue) => {
          return prevValue.filter((e) => e !== filter.value);
        });
      } else {
        setSelectedStatuses((prevValue) => {
          return [...prevValue, filter.value];
        });
      }
    },
    [selectedStatuses]
  );

  const filterByActiveStatus = (activeStatuses, arr) => {
    return arr && arr.length && arr?.filter((e) => activeStatuses?.includes(e.filterStatus));
  };

  const handleTabChange = useCallback(
    (e) => {
      if (e.event !== undefined) {
        const newValue = e.itemIndex;

        setTabValue(newValue);

        if (newValue !== 1 || newValue !== 0) {
          setRequestIdToFilter(null);
        }
        if (rId) {
          navigate(`/views/manage-headcounts${dgId !== null ? `?dg=${dgId}` : ''}`, {
            replace: true
          });
        }
      }
    },
    [tabValue]
  );

  const handleActionButtonText = useCallback(() => {
    navigate('/views/manage-supervisor');
  }, []);

  return (
    // <BaseLayOut
    //   pageTitle={pageName ?? 'Manage Supervisors'}
    //   isForTransfer={true}
    //   pageView={pageView}
    //   setPageView={setPageView}
    //   setTabValue={setTabValue}
    // >
    <>
      <div className="__body__section __manage__bulk__headcounts__">
        <div className="__manage__headcounts__container__">
          <BlackSectionController>
            <div className="__intro__section__title__description__">
              <h1 className="__primary__color">Manage Supervisors</h1>
              <ColumnPreferences
                actionButtonWidth={150}
                actionButtonText="Create Supervisor"
                handleActionButtonText={handleActionButtonText}
                // updateDataGridTableColumns={updateDataGridTableColumns}
                // handleClearDataGridColumnFilters={handleClearDataGridColumnFilters}
                // setColumnPreferences={setColumnPreferences}
              />
            </div>
          </BlackSectionController>
        </div>
        <div className="__manage__headcounts__content__section__">
          <div className="__manage__headcounts__content__row__">
            <div className="__tabs__section__">
              <Tabs
                className="__tabs__content__"
                width={400}
                selectedIndex={tabValue}
                onItemClick={handleTabChange}
                aria-label="tabs"
              >
                <Item
                  render={() => (
                    <div className="__font__family__regular __left__tab__">Employee View</div>
                  )}
                />
                <Item
                  render={() => (
                    <div className="__font__family__regular __right__tab__">Request View</div>
                  )}
                />
              </Tabs>
            </div>
            {tabValue === 1 && (
              <GroupsDataGrid
                rows={dataSource}
                columns={groups?.columns ?? []}
                routeKey={routeKey}
                permissions={permissions}
                isLoading={isLoading}
                hasMore={hasMore}
                setTab={setTabValue}
                setrIdToFilter={setRequestIdToFilter}
                setPage={setPageView}
                transactionType="transaction_transfer"
                fetchDataFromApi={getAllTransferGroups}
              />
            )}
            {tabValue === 0 && (
              <div>
                {/* TODO:: hide it for now once UI is finalized and decided where to show (Filters) */}
                {/* <div className="lights-legend__container">
                    {allTransfers?.allHcFilters?.map((filter) => (
                      <div
                        key={filter.value}
                        onClick={() => {
                          handleLegendClicked(filter);
                        }}
                        className={`lights-legend__item ${
                          selectedStatuses?.includes(filter.value)
                            ? 'lights-legend__item--active'
                            : ''
                        }`}
                      >
                        <div
                          className="lights-legend__color"
                          style={{
                            backgroundColor: filter?.color ? filter.color : 'transparent'
                          }}
                        ></div>
                        {filter?.value}
                      </div>
                    ))}
                  </div> */}

                <AllTransfersDataGrid
                  dataAccess={dataAccess}
                  transactionType={'transaction_transfer'}
                  setrIdToFilter={setRequestIdToFilter}
                  requestIdFilter={requestIdFilter}
                  rows={
                    requestIdFilter !== null && allHcDs?.length
                      ? allHcDs?.filter((l) => l?.transactionTransferGroupId === requestIdFilter)
                      : allHcDs
                  }
                  columns={allTransfers?.columns ?? []}
                  dropDownData={allTransfers?.dropdownValues ?? {}}
                  transferFormColumns={allTransfers?.transferFormColumns ?? {}}
                  fields={allTransfers?.fields ?? {}}
                  routeKey={routeKey}
                  permissions={permissions}
                  isLoading={isLoading}
                  apiCallBack={async () => {
                    await getAllTransfers();
                    await getUserDataAccessByModule(getUserInfo().id, 7);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {/* TODO:: remove once tested okay */}
      {false && (
        <MDBox my={2}>
          <Card>
            <MDBox>
              <MDBox sx={{ width: '100%' }}>
                <MDBox sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={tabValue} onChange={handleChange} aria-label="tabs">
                    {pageView ? <Tab label="Request View" {...a11yProps(0)} /> : ''}
                    {!pageView ? <Tab label="Employee View" {...a11yProps(0)} /> : ''}
                  </Tabs>
                </MDBox>
                <TabPanel value={tabValue} index={pageView ? 0 : null}>
                  {groups && groups.columns && groups.columns.length > 0 ? (
                    <GroupsDataGrid
                      rows={dataSource}
                      columns={groups && groups.columns ? groups.columns : []}
                      routeKey={routeKey}
                      permissions={permissions}
                      isLoading={isLoading}
                      hasMore={hasMore}
                      setTab={setTabValue}
                      setrIdToFilter={setRequestIdToFilter}
                      setPage={setPageView}
                      transactionType="transaction_transfer"
                      fetchDataFromApi={getAllTransferGroups}
                    />
                  ) : (
                    <MDBox mt={1} mb={1} style={{ textAlign: 'center' }}>
                      <Image
                        title="Loading"
                        alt="Loading"
                        width={70}
                        src={loadingGif}
                        preview={false}
                      />
                    </MDBox>
                  )}
                </TabPanel>
                <TabPanel value={tabValue} index={!pageView ? 0 : null}>
                  {allTransfers && allTransfers.columns && allTransfers.columns.length > 0 ? (
                    <div>
                      <div className="lights-legend__container">
                        {allTransfers?.allHcFilters?.map((filter) => (
                          <div
                            key={filter.value}
                            onClick={() => {
                              handleLegendClicked(filter);
                            }}
                            className={`lights-legend__item ${
                              selectedStatuses?.includes(filter.value)
                                ? 'lights-legend__item--active'
                                : ''
                            }`}
                          >
                            <div
                              className="lights-legend__color"
                              style={{
                                backgroundColor: filter?.color ? filter.color : 'transparent'
                              }}
                            ></div>
                            {filter?.value}
                          </div>
                        ))}
                      </div>

                      <AllTransfersDataGrid
                        dataAccess={dataAccess}
                        transactionType={'transaction_transfer'}
                        setrIdToFilter={setRequestIdToFilter}
                        requestIdFilter={requestIdFilter}
                        rows={
                          requestIdFilter !== null && allHcDs?.length
                            ? allHcDs?.filter(
                                (l) => l?.transactionTransferGroupId === requestIdFilter
                              )
                            : allHcDs
                        }
                        columns={allTransfers && allTransfers.columns ? allTransfers.columns : []}
                        dropDownData={
                          allTransfers && allTransfers.dropdownValues
                            ? allTransfers.dropdownValues
                            : {}
                        }
                        transferFormColumns={
                          allTransfers && allTransfers.transferFormColumns
                            ? allTransfers.transferFormColumns
                            : {}
                        }
                        fields={allTransfers && allTransfers.fields ? allTransfers.fields : {}}
                        routeKey={routeKey}
                        permissions={permissions}
                        isLoading={isLoading}
                        apiCallBack={async () => {
                          await getAllTransfers();
                          await getUserDataAccessByModule(getUserInfo().id, 7);
                        }}
                      />
                    </div>
                  ) : (
                    <MDBox mt={1} mb={1} style={{ textAlign: 'center' }}>
                      <Image
                        title="Loading"
                        alt="Loading"
                        width={70}
                        src={loadingGif}
                        preview={false}
                      />
                    </MDBox>
                  )}
                </TabPanel>
              </MDBox>
            </MDBox>
          </Card>
        </MDBox>
      )}
    </>
    // </BaseLayOut>
  );
}
