import React, { useContext, useEffect } from 'react';
import Loadable from 'react-loadable';
import { Context } from 'utils/context/store/Store';
import useNavListing from 'utils/hooks/useNavListing';
import BaseLayOut from '../../components/Layout/BaseLayOut';
import usePagePermissions from '../../utils/hooks/usePagePermissions';
import Skelton from '../../components/Skelton/defaultSkelton';
const NavigationDataGrid = Loadable({
  loader: () => import('../../views/ui-management/DataGrid/NavigationDataGrid'),
  loading: () => <Skelton />
});

function ManageNavListing({ routeKey, pageName }) {
  const { getNavListing, updateOrCreateNav, isLoading } = useNavListing(routeKey);
  const [{ navListing }, dispatch] = useContext(Context);
  const { getPermissionsForPage } = usePagePermissions(routeKey);

  useEffect(async () => {
    await getNavListing();
  }, []);

  return (
    <div className="__settings__container__right__section__">
      <NavigationDataGrid
        isLoading={isLoading}
        columns={navListing.columns ?? []}
        rows={navListing.rows ?? []}
        permissions={getPermissionsForPage(routeKey)}
        dropDownData={navListing.dropdownValues ?? []}
        hitApi={updateOrCreateNav}
        allowAdding={false}
        allowDeletingFromApi={false}
        pagePropertyColumns={navListing.pagePropertyColumns ?? []}
        getNavListingOfParent={getNavListing}
      />
    </div>
  );
}

export default ManageNavListing;
