import { Context } from '../context/store/Store';
import { useIsLoading } from './useIsLoading';
import request from '../services/Http';
import { useContext, useState } from 'react';
import { getPageProperties, getTableColumns } from '../services/Helpers';

export default function useEmailDeliveryStatusHub(routeKey) {
  const { isLoading, setIsLoading } = useIsLoading();
  const [stats, setStats] = useState({});
  const [{ pagesAndPermissions }, dispatch] = useContext(Context);

  async function getAllEmailDeliveryStatusHub() {
    setIsLoading(true);
    try {
      const res = await request.get('email-delivery-status-hub');
      const properties = getPageProperties(pagesAndPermissions, routeKey);
      const table = getTableColumns(properties.page.table, routeKey);
      let columns = table.columns;
      const data = { rows: res.data.data, columns };
      if (res) setStats(data);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  }

  return {
    isLoading,
    getAllEmailDeliveryStatusHub,
    stats
  };
}
