import React, { useEffect, useState, useCallback } from 'react';
import ReportViewer from 'react-lighthouse-viewer';
import BaseLayOut from '../../components/Layout/BaseLayOut';
import usePerformanceMetric from '../../utils/hooks/usePerformanceMetric';
import MDBox from '../../components/MDBox';
import Tabs, { Item } from 'devextreme-react/tabs';
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
import { a11yProps, DEButton } from '../../utils/services/Helpers';
import TabPanel from '../../components/TabPanel';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CustomSkelton from '../../components/Skelton/CustomSkelton';
import Skelton from '../../components/Skelton/defaultSkelton';

const PerformanceMetric = ({ routeKey, pageName }) => {
  const [tabValue, setTabValue] = useState(0);
  const { getMetrics, metrics, isLightHouseRunning, isLoading, startAudit } =
    usePerformanceMetric();

  useEffect(() => {
    const fn = async () => await getMetrics();
    fn();
  }, []);

  const handleChange = useCallback(
    (e) => {
      if (e.event !== undefined) {
        setTabValue(e.itemIndex);
      }
    },
    [tabValue]
  );

  if (isLoading) {
    return <Skelton />;
  }

  return (
    <div className="__settings__container__right__section__">
      <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '10px' }}>
        <DEButton
          className="__default__button__layout"
          disabled={isLightHouseRunning}
          stylingMode={'contained'}
          type={'success'}
          onClick={async () => await startAudit()}
          text={isLightHouseRunning ? 'Audit is in Progress' : 'Start Audit'}
        />
      </div>
      <br />
      <Tabs
        className="__tabs__content__"
        width={'auto'}
        selectedIndex={tabValue}
        onItemClick={handleChange}
        aria-label="tabs"
      >
        {metrics.map((obj, i) => {
          return (
            <Item
              key={`metrics-tab-${i}`}
              render={() => <div className="__font__family__regular">{obj.url}</div>}
            />
          );
        })}
      </Tabs>
      {/* <Tabs value={tabValue} onChange={handleChange} aria-label="tabs">
          {metrics?.map((d, i) => {
            return <Tab key={i} label={d.url} {...a11yProps(i)} />;
          })}
        </Tabs> */}
      {metrics?.map((d, i) => {
        return tabValue === i ? <ReportViewer json={metrics[tabValue]?.report} /> : null;
      })}
    </div>
  );
};

export default PerformanceMetric;
