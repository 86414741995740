import React, { useContext, useEffect } from 'react';
import Loadable from 'react-loadable';
import { Context } from 'utils/context/store/Store';
import useEmployee from 'utils/hooks/useEmployee';
import usePagePermissions from 'utils/hooks/usePagePermissions';
import BaseLayOut from '../../components/Layout/BaseLayOut';
import useBulkUpload from 'utils/hooks/useBulkUpload';
import Skelton from '../../components/Skelton/defaultSkelton';
const EmployeeDataGrid = Loadable({
  loader: () => import('./DataTable/EmployeeDataGrid'),
  loading: () => <Skelton />
});

function ManageEmployee({ routeKey, pageName }) {
  const { getEmployee, deleteEmployee, updateOrCreateEmployee, isLoading } = useEmployee(routeKey);
  const { bulkUpload } = useBulkUpload(routeKey);
  const [{ employee }, dispatch] = useContext(Context);
  const { getPermissionsForPage } = usePagePermissions(routeKey);

  useEffect(async () => {
    await getEmployee();
  }, []);
  useEffect(() => {}, [employee]);

  return (
    // <BaseLayOut pageTitle={pageName ?? 'Manage Employees'}>
    <div className="__settings__container__right__section__">
      <EmployeeDataGrid
        isLoading={isLoading}
        rows={employee.rows ?? []}
        columns={employee.columns}
        permissions={getPermissionsForPage(routeKey)}
        dropDownData={employee.dropdownValues}
        postData={updateOrCreateEmployee}
        handleDelete={deleteEmployee}
        allowSelection={true}
        allowAdding={true}
        allowDeletingFromApi={true}
        bulkUploadApi={bulkUpload}
        apiCallback={getEmployee}
        tableName={employee.tableName}
        uploadTemplateLink={employee.uploadTemplateLink}
        orgStructureLink={employee.orgStructureLink}
      />
    </div>
    // </BaseLayOut>
  );
}

export default ManageEmployee;
