import React, { useContext, useEffect, memo } from 'react';
import Loadable from 'react-loadable';
import { Context } from '../../utils/context/store/Store';
import useSegments from '../../utils/hooks/useSegments';
import usePagePermissions from '../../utils/hooks/usePagePermissions';
import Skelton from '../../components/Skelton/defaultSkelton';
const SegmentsDataGrid = Loadable({
  loader: () => import('./DataTable/SegmentsDataGrid'),
  loading: () => <Skelton />
});
import useBulkUpload from 'utils/hooks/useBulkUpload';
import './styles.scss';

const ManageSegments = memo(({ routeKey, pageName }) => {
  const { getSegments, deleteSegment, updateOrCreateSegments, isLoading, clearComponentSegments } =
    useSegments(routeKey);
  const { bulkUpload } = useBulkUpload(routeKey);
  const [{ segments }, dispatch] = useContext(Context);
  const { getPermissionsForPage } = usePagePermissions(routeKey);

  useEffect(async () => {
    await getSegments();

    return () => {
      clearComponentSegments();
    };
  }, []);

  return (
    <div className="__body__section __base__layout__container __manage__segments__">
      <SegmentsDataGrid
        loading={isLoading}
        columns={segments.columns}
        rows={segments.rows}
        dropDownData={segments.dropdownValues}
        objToAdd={segments.newRowObject}
        postData={updateOrCreateSegments}
        handleDelete={deleteSegment}
        isSegments={true}
        routeKey={routeKey}
        permissions={getPermissionsForPage()}
        allowSelection={false}
        allowAdding={true}
        allowDeleting={true}
        allowUpdating={true}
        elementColumns={segments.elementColumns}
        bulkUploadApi={bulkUpload}
        apiCallback={getSegments}
        tableName={segments.tableName}
        uploadTemplateLink={segments.uploadTemplateLink}
        orgStructureLink={segments.orgStructureLink}
        allowBulkUploading={true}
      />
    </div>
  );
});

export default ManageSegments;
