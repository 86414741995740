import request from '../services/Http';
import { useContext } from 'react';
import Swal from 'sweetalert2';
import { Context } from '../context/store/Store';
import {
  SET_HOMEPAGE_TASKS,
  SET_DASHBOARD_OBJECT,
  SET_IS_LOADING,
  SET_PENDING_TASK,
  SET_TASKS
} from '../context/store/Constants';
import { MY_TASK_TRANSACTION_TYPES } from '../constant';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useIsLoading } from './useIsLoading';
import { getPageProperties, getTableColumns } from '../services/Helpers';
import { useParams } from 'react-router-dom';

export default function useMyTasks(routeKey) {
  const [{ myTasks, pagesAndPermissions, pendingTasks }, dispatch] = useContext(Context);
  const { isLoading, setIsLoading } = useIsLoading();
  const { masterModuleId } = useParams();

  async function getMyTasks() {
    setIsLoading(true);

    try {
      const res = await request.get('my-task');

      if (res) {
        dispatch({ type: SET_PENDING_TASK, payload: res.data.pendingActions });
        dispatch({ type: SET_HOMEPAGE_TASKS, payload: res.data.data });

        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
    }
  }

  async function setMyTasks(data) {
    dispatch({ type: SET_PENDING_TASK, payload: data?.pendingActions ?? [] });
    dispatch({ type: SET_HOMEPAGE_TASKS, payload: data?.data ?? [] });
    setIsLoading(false);
  }

  function clearMyTasks() {
    dispatch({ type: SET_PENDING_TASK, payload: [] });
    dispatch({ type: SET_HOMEPAGE_TASKS, payload: [] });
  }

  async function getDashboardObject() {
    setIsLoading(true);
    dispatch({ type: SET_IS_LOADING, payload: true });
    try {
      const res = await request.get('my-task/dashboard');
      dispatch({ type: SET_DASHBOARD_OBJECT, payload: res.data.dashboardObject });
      return res.data.dashboardObject;
    } catch (e) {}
    setIsLoading(false);
    dispatch({ type: SET_IS_LOADING, payload: false });
  }

  function updatePendingtasksAfterRejectOrApproval({ type }) {
    const pendingTasksDuplicate = [...pendingTasks];
    pendingTasksDuplicate.forEach(({ count, transactionType }, i) => {
      if (transactionType === type && count > 0) {
        pendingTasksDuplicate[i].count = count - 1;
        return;
      }
    });
  }

  async function handleApproveRejectTask(myTaskObject) {
    try {
      const approveReject = myTaskObject.actionType === 'approve';
      const titleName = MY_TASK_TRANSACTION_TYPES[myTaskObject.transactionType];
      Swal.fire({
        title: approveReject ? `Approve ${titleName}!` : `Reject ${titleName}!`,
        text: 'Please enter your comments:',
        input: 'textarea',
        showCancelButton: true,
        confirmButtonText: approveReject ? 'Approve' : 'Reject',
        confirmButtonColor: approveReject ? '#79a479' : '#d56f6c',
        customClass: {
          container: '__swal__continer__ __font__family__regular',
          confirmButton: '__default__button__layout __btn__green __primary__color __unset__border',
          cancelButton: '__default__button__layout __primary__color __unset__border'
        },
        inputValidator: (value) => {
          return !approveReject && !value && 'Please enter comments!';
        }
      }).then(async (result) => {
        dispatch({ type: SET_IS_LOADING, payload: true });
        if (result.isConfirmed) {
          let removedTask = {};
          const taskList = [...myTasks].filter((task) => {
            if (task.id === myTaskObject.data[0]) {
              removedTask = task;
            }

            return task.id !== myTaskObject.data[0];
          });
          const res = await request.post('transaction-approval/my-tasks-action', {
            ...myTaskObject,
            comments: result.value
          });
          if (res) {
            toast.success(res.data.data);
            if (taskList?.length === 0) {
              // tasks length is ZERO then remove the section from DOM...
              clearMyTasks();
            } else {
              dispatch({ type: SET_HOMEPAGE_TASKS, payload: taskList });
              updatePendingtasksAfterRejectOrApproval(removedTask);
            }
          } else {
            dispatch({ type: SET_HOMEPAGE_TASKS, payload: [...myTasks] });
          }
          dispatch({ type: SET_IS_LOADING, payload: false });
        } else {
          dispatch({ type: SET_IS_LOADING, payload: false });
        }
      });
    } catch (e) {
      dispatch({ type: SET_HOMEPAGE_TASKS, payload: [...myTasks] });
      dispatch({ type: SET_IS_LOADING, payload: false });
    }
  }

  async function deleteMyTasks(data) {
    setIsLoading(true);
    dispatch({ type: SET_IS_LOADING, payload: true });
    try {
      const res = await request.delete('my-task', {
        data: {
          jd: data
        }
      });
      await getMyTasks();
      toast.success(res.data.data);
    } catch (e) {}
    setIsLoading(false);
    dispatch({ type: SET_IS_LOADING, payload: false });
  }

  return {
    getMyTasks,
    getDashboardObject,
    deleteMyTasks,
    handleApproveRejectTask,
    clearMyTasks,
    myTasks,
    pendingTasks,
    isLoading,
    setMyTasks
  };
}
