import React, { useCallback, useEffect, useState } from 'react';
import Loadable from 'react-loadable';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import { registerAllModules } from 'handsontable/registry';
import Skelton from '../../../components/Skelton/defaultSkelton';
const HandsOnTableComponent = Loadable({
  loader: () => import('../components/handsOnTable'),
  loading: () => <Skelton />
});
const ActionButtons = Loadable({
  loader: () => import('../components/actionButtons'),
  loading: () => <Skelton />
});
const TableView = Loadable({
  loader: () => import('../components/tableView'),
  loading: () => <Skelton />
});
import useFinancialForecast from '../../../utils/hooks/useFinancialForecast';
import 'handsontable/dist/handsontable.min.css';
import '../components/styles.scss';
import usePagePermissions from 'utils/hooks/usePagePermissions';
import { getUserInfo } from '../../../utils/services/Helpers';

registerAllModules();

const ApproverFinancialForecast = ({ routeKey, pageName }) => {
  const [isFullScreen, setFullScreen] = useState(false);
  const [hotTableRef, setHotTableRef] = useState(null);
  const { getPermissionsForPage } = usePagePermissions(routeKey);
  const hookFinancialForecast = useFinancialForecast(true);
  const permissions = getPermissionsForPage(routeKey)

  const {
    headers, renderTable, dataSource,
    dropdownData, getDropdownData,
    isSheetSubmitted, canActionSheet,
    socket, setDropdownData
  } = hookFinancialForecast;

  useEffect(() => {
    const callback = async () => {
      await getDropdownData();
    };
    callback();
  }, []);
  useEffect(() => {
    const userId = getUserInfo()?.id;
    const eventName1 = `dd_rf_${userId}`;
    const handleSocketEvent = async ({ key, chunk }) => {
      if (key && chunk) {
        setDropdownData(prevState => ({ ...prevState, [key]: [...(prevState[key] || []), ...chunk], }));
      }

    };
    // Attach socket event listener
    socket.on(eventName1, handleSocketEvent);
    // Cleanup function
    return () => {
      socket.off(eventName1, handleSocketEvent); // Remove specific listener
    };
  }, [socket]); // Dependency array includes only socket
  useEffect(() => { }, [hotTableRef]);

  const handsOnTable = useCallback(() => {
    return (
      <>
        {renderTable && dataSource && dataSource.length && hookFinancialForecast.hfm?.length ? (
          <HandsOnTableComponent
            disabled={true}
            setHotTableRef={setHotTableRef}
            dataWithIDs={dropdownData?.data}
            permissions={getPermissionsForPage(routeKey)}
            {...hookFinancialForecast}
          />
        ) : hookFinancialForecast.hfm?.length ? (
          <div style={{ textAlign: 'center', padding: '20px', fontSize: '16px', color: '#888' }}>
            No data available to perform this action.
          </div>
        ) : null}
      </>
    );
  }, [
    headers, dataSource, dropdownData, isSheetSubmitted, hotTableRef,
    setHotTableRef, renderTable, canActionSheet, dropdownData
  ]);

  return (
    <div className="__body__section __base__layout__container __financial__forcast__">
      <h3>Financial Forecast</h3>
      <div className="__financial__forcast__section__">
        <div className="__financial__forecast__action__buttons__">
          <ActionButtons multiselect={true} permissions={permissions} hotTableRef={hotTableRef} {...{ ...hookFinancialForecast }} />
        </div>
        {
          hookFinancialForecast.cyclePeriod && hookFinancialForecast.hfm
          && <TableView showCommaSeperatedValues={true} {...{ ...hookFinancialForecast }} />
        }

        <div className="__financial__forecast__table__">
          {renderTable && dataSource && handsOnTable()}
        </div>
      </div>
      <Popup
        height={'auto'}
        visible={isFullScreen}
        className="__font__family"
        dragEnabled={false}
        onHiding={() => setFullScreen(false)}
        contentRender={() => (
          <ScrollView width="100%" height="100%">
            <div className="__financial__forcast__section__">
              <div className="__financial__forecast__action__buttons__">
                <ActionButtons {...{ ...hookFinancialForecast }} />
              </div>
              <div className="__financial__forecast__table__ __mt__15">
                {renderTable && handsOnTable()}
              </div>
            </div>
          </ScrollView>
        )}
      >
        <ToolbarItem
          render={() => <h2 className="__font__family __mb__0">Financial Forecast</h2>}
          location="center"
          toolbar="top"
        />
      </Popup>
    </div>
  );
};

export default ApproverFinancialForecast;
