import React, { useEffect, memo } from 'react';
import Loadable from 'react-loadable';
import BaseLayOut from '../../components/Layout/BaseLayOut';
import useExitManagement from '../../utils/hooks/useExitManagement';
import usePagePermissions from '../../utils/hooks/usePagePermissions';
import { useParams } from 'react-router-dom';
import Skelton from '../../components/Skelton/defaultSkelton';
const EmployeeResignation = Loadable({
  loader: () => import('./EmployeeResignation'),
  loading: () => <Skelton />
});

const ExitManagement = memo(({ routeKey, pageName }) => {
  const { transactionExitId } = useParams();
  const {
    getEmployeeExitRecord,
    createOrUpdateExitRecords,
    cancelExitRequest,
    empExitDetails,
    isLoading
  } = useExitManagement(routeKey);
  const { getPermissionsForPage } = usePagePermissions(routeKey);

  useEffect(() => {
    const fn = async () => await getEmployeeExitRecord(transactionExitId);
    fn();
  }, [transactionExitId]); // Add transactionExitId to dependencies

  return (
    // <BaseLayOut pageTitle={pageName ?? ''}>
    <div className="__body__section __base__layout__container __manage__segments__">
      <h3>Exit Management</h3>
      <EmployeeResignation
        resignationData={empExitDetails?.rows}
        masterEmployeeDetail={empExitDetails?.masterEmployeeDetail}
        dropdownData={empExitDetails?.dropdownData}
        onSubmit={createOrUpdateExitRecords}
        onCancel={cancelExitRequest}
        apiCallBack={getEmployeeExitRecord}
      />
    </div>
    // </BaseLayOut>
  );
});

export default ExitManagement;
