import React, { useState, useEffect, useRef } from 'react';
import { SelectBox } from 'devextreme-react/select-box';
import { DropDownBox } from 'devextreme-react/drop-down-box';
import { List } from 'devextreme-react/list';
import { dataSourceForHeaderVersion } from './utils';

const Versions = ({
  handleLoadSelectVersionHeader,
  selectedTypeHeaderVersions,
  isResetDropdownVersionValue,
  setIsResetDropdownVersionValue,
  removeVersion
}) => {
  const dropDownRef = useRef(null);
  const [dropDownValue, setDropDownValue] = useState(null);

  const closeDropdown = (id) => {
    setDropDownValue(id);
    // dropDownRef.current.instance.close();
  };

  const resetDropdownValue = () => {
    if (dropDownRef.current && dropDownRef.current.instance) {
      setDropDownValue(null);
      // dropDownRef.current.instance.reset();
    }
  };

  useEffect(() => {
    if (isResetDropdownVersionValue) {
      resetDropdownValue();
      setIsResetDropdownVersionValue(false);
    }
  }, [isResetDropdownVersionValue]);

  return (
    <>
      <SelectBox
        placeholder={`Version`}
        dataSource={{
          paginate: true,
          pageSize: 10,
          store: dataSourceForHeaderVersion(selectedTypeHeaderVersions) ?? []
        }}
        value={dropDownValue}
        searchEnabled={true}
        showClearButton={true}
        valueExpr={'id'}
        displayExpr={'name'}
        onValueChanged={(e) => {
          if (e.event !== undefined) {
            handleLoadSelectVersionHeader(e.value);
            closeDropdown(e.value);
          }
        }}
        className={`__ff__header__dropdown display-inline-block`}
      />

      {/* TODO: remove once tested okay */}
      {false && (
        <DropDownBox
          value={dropDownValue}
          ref={dropDownRef}
          key={`header-versions`}
          width={200}
          className="__ff__header__dropdown display-inline-block"
          placeholder={'Version'}
          acceptCustomValue={true}
          openOnFieldClick={false}
          valueExpr="id"
          displayExpr="name"
          dataSource={dataSourceForHeaderVersion(selectedTypeHeaderVersions)}
        >
          <List
            selectionMode="single"
            valueExpr="id"
            displayExpr="name"
            dataSource={dataSourceForHeaderVersion(selectedTypeHeaderVersions)}
            allowItemDeleting={true}
            onItemDeleting={(e) => {
              removeVersion(e.itemData.id);
            }}
            onItemClick={(e) => {
              handleLoadSelectVersionHeader(e.itemData.id);
              closeDropdown(e.itemData.id);
            }}
          />
        </DropDownBox>
      )}
    </>
  );
};

export default Versions;
