import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react';
import useHeadcountRequest from 'utils/hooks/useHeadcountRequest';
import { NumberBox, SelectBox, TextArea, Button } from 'devextreme-react';
import 'devextreme/dist/css/dx.light.css';
import { Backdrop, CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';

const BriefingTemplateForm = forwardRef(
  ({ id = null, apiCallBack = null, showButtons = true, onStatusChange = () => {} }, ref) => {
    const { getBriefingTemplateData, updateBriefingTemplateData, isLoading, btData } =
      useHeadcountRequest();
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});
    const [formChanged, setFormChanged] = useState(false);
    const initialLoadRef = useRef(true);

    const requiredFields = [
      'reportTo',
      'teamStructure',
      'directReports',
      'relocation',
      'scope',
      'downgrade',
      'significance',
      'bigWins',
      'dailyTasks',
      'skillsMustHave',
      'skillsNiceToHave',
      'qualities',
      'clientDetails',
      'interviewQuestions',
      'interviewSchedule'
    ];

    useEffect(() => {
      const searchParams = new URLSearchParams(window.location.search);
      const tokenParam = searchParams.get('token') ?? null;
      if (tokenParam) localStorage.setItem('tokenParam', tokenParam);
      // const fetchData = async () => getBriefingTemplateData(id);

      const handlePageLoad = () => {
        const fetchData = async () => getBriefingTemplateData(id);
        if (localStorage.getItem('_token')) fetchData();
      };

      if (showButtons) {
        // Add event listener for page load
        window.addEventListener('load', handlePageLoad);
      } else {
        const fetchData = async () => getBriefingTemplateData(id);
        if (localStorage.getItem('_token')) fetchData();
      }

      // cleanup on unmount
      return () => {
        setFormData({});
        setErrors({});
        setFormChanged(false);
        onStatusChange(false, false);
        showButtons ? window.removeEventListener('load', handlePageLoad) : null;
      };
    }, []);

    useEffect(() => {
      if (btData) {
        setFormData(btData);
        initialLoadRef.current = false;
        onStatusChange(btData.status, formChanged);
      }
    }, [btData]);

    useEffect(() => {
      /* console.log('formData', formData) */
    }, [formData]);

    const handleChange = (field) => (e) => {
      if (initialLoadRef.current || e.value === null || e.value === undefined) {
        setFormData((prevFormData) => ({ ...prevFormData, [field]: null }));
        return;
      }
      setFormData((prevFormData) => ({ ...prevFormData, [field]: e.value }));
      setFormChanged(true);
      onStatusChange(formData?.status, true);
      if (requiredFields.includes(field) && e.value) {
        setErrors((prevErrors) => ({ ...prevErrors, [field]: '' }));
      }
    };

    const handleSubmit = async () => {
      const newErrors = {};
      requiredFields.forEach((field) => {
        if (formData[field] === null) {
          newErrors[field] = 'This field is required';
        }
      });
      if (Object.keys(newErrors).length > 0) {
        toast.error('Please fill in the data!');
        setErrors(newErrors);
      } else {
        delete formData?.employees;
        delete formData?.status;
        await updateBriefingTemplateData(formData, id, apiCallBack);
        setFormChanged(false);
        onStatusChange(formData?.status, false);
      }
    };

    const handleClear = () => {
      setFormData((prevFormData) => {
        const clearedFormData = Object.fromEntries(
          Object.entries(prevFormData).map(([key, value]) =>
            ['id', 'status', 'entityName', 'jobName', 'employees'].includes(key)
              ? [key, value]
              : [key, '']
          )
        );
        return clearedFormData;
      });
      setErrors({});
      setFormChanged(false);
      onStatusChange(formData?.status, false);
    };

    const renderField = (label, placeholder, children, field) => (
      <div style={styles.field}>
        <label style={styles.label}>
          {label}
          {requiredFields.includes(field) && <span style={{ color: 'red' }}> *</span>}
        </label>
        <div style={styles.inputContainer}>
          {React.cloneElement(children, { placeholder })}
          {errors[field] && <p style={styles.error}>{errors[field]}</p>}
        </div>
      </div>
    );

    useImperativeHandle(ref, () => ({
      handleSubmit,
      handleClear
    }));

    return (
      <div className="form-container" style={styles.container}>
        <h1 style={styles.header}>Briefing Template for Talent Acquisition</h1>
        <p style={styles.subHeader}>
          We’re on a mission to find a brilliant <b>{formData?.jobName}</b> for our team at{' '}
          <b>{formData?.entityName}</b>, and your insights are key!
        </p>

        <Section title="Role Basics">
          {renderField(
            'Who They Report To',
            'Who’s their go-to person?',
            <SelectBox
              dataSource={{
                paginate: true,
                pageSize: 10,
                store: formData?.employees ?? []
              }}
              value={formData?.reportTo}
              valueExpr="id"
              displayExpr="label"
              showClearButton={true}
              searchEnabled={true}
              disabled={formData?.status}
              onValueChanged={handleChange('reportTo')}
            />,
            'reportTo'
          )}
          {renderField(
            'Team Structure',
            'How many people in the team?',
            <NumberBox
              value={formData?.teamStructure}
              onValueChanged={handleChange('teamStructure')}
              min={0}
              disabled={formData?.status}
            />,
            'teamStructure'
          )}
          {renderField(
            'Direct Reports',
            'How many people will be reporting to them if any?',
            <NumberBox
              value={formData?.directReports}
              onValueChanged={handleChange('directReports')}
              min={0}
              disabled={formData?.status}
            />,
            'directReports'
          )}
          {renderField(
            'Relocation',
            'Are we okay to relocate talents for this position from different markets?',
            <TextArea
              value={formData?.relocation}
              onValueChanged={handleChange('relocation')}
              autoResizeEnabled
              minHeight={'53px'}
              disabled={formData?.status}
            />,
            'relocation'
          )}
          {renderField(
            'Scope',
            'Is their role local, regional, or global (state which markets)?',
            <TextArea
              value={formData?.scope}
              onValueChanged={handleChange('scope')}
              autoResizeEnabled
              minHeight={'53px'}
              disabled={formData?.status}
            />,
            'scope'
          )}
          {renderField(
            'Downgrade',
            'Would you consider downgrading the role? This will help us understand if we can widen the pool to slightly more junior talent to the HC approved.',
            <TextArea
              value={formData?.downgrade}
              onValueChanged={handleChange('downgrade')}
              autoResizeEnabled
              minHeight={'53px'}
              disabled={formData?.status}
            />,
            'downgrade'
          )}
        </Section>

        <Section title="Role Impact & Goals">
          {renderField(
            'Significance',
            'Why is this job important to us?',
            <TextArea
              value={formData?.significance}
              onValueChanged={handleChange('significance')}
              autoResizeEnabled
              minHeight={'53px'}
              disabled={formData?.status}
            />,
            'significance'
          )}
          {renderField(
            'Big Wins',
            'What are the key things they should achieve in the first year?',
            <TextArea
              value={formData?.bigWins}
              onValueChanged={handleChange('bigWins')}
              autoResizeEnabled
              minHeight={'53px'}
              disabled={formData?.status}
            />,
            'bigWins'
          )}
        </Section>

        <Section title="The Day-to-Day">
          {renderField(
            'Daily Tasks',
            'Quick run-down of their daily tasks and who they’ll work with.',
            <TextArea
              value={formData?.dailyTasks}
              onValueChanged={handleChange('dailyTasks')}
              autoResizeEnabled
              minHeight={'53px'}
              disabled={formData?.status}
            />,
            'dailyTasks'
          )}
          {renderField(
            'Skills Must Have',
            'What skills must they have?',
            <TextArea
              value={formData?.skillsMustHave}
              onValueChanged={handleChange('skillsMustHave')}
              autoResizeEnabled
              minHeight={'53px'}
              disabled={formData?.status}
            />,
            'skillsMustHave'
          )}
          {renderField(
            'Skills Nice to Have',
            'What skills would be nice to have?',
            <TextArea
              value={formData?.skillsNiceToHave}
              onValueChanged={handleChange('skillsNiceToHave')}
              autoResizeEnabled
              minHeight={'53px'}
              disabled={formData?.status}
            />,
            'skillsNiceToHave'
          )}
          {renderField(
            'Qualities',
            'Beyond skills, what personal qualities are we after?',
            <TextArea
              value={formData?.qualities}
              onValueChanged={handleChange('qualities')}
              autoResizeEnabled
              minHeight={'53px'}
              disabled={formData?.status}
            />,
            'qualities'
          )}
        </Section>

        <Section title="Digging Deeper">
          {renderField(
            'Clients',
            'Anything specific or unique information about the client worth mentioning?',
            <TextArea
              value={formData?.clientDetails}
              onValueChanged={handleChange('clientDetails')}
              autoResizeEnabled
              minHeight={'53px'}
              disabled={formData?.status}
            />,
            'clientDetails'
          )}
          {renderField(
            'Interview Questions',
            'Questions that will help TA assess the candidates better. Share it here (technical ones).',
            <TextArea
              value={formData?.interviewQuestions}
              onValueChanged={handleChange('interviewQuestions')}
              autoResizeEnabled
              minHeight={'53px'}
              disabled={formData?.status}
            />,
            'interviewQuestions'
          )}
          {renderField(
            'Interview Schedule',
            'Please mention the stakeholders that will be conducting the interviews below post the TA screen.',
            <TextArea
              value={formData?.interviewSchedule}
              onValueChanged={handleChange('interviewSchedule')}
              autoResizeEnabled
              minHeight={'53px'}
              disabled={formData?.status}
            />,
            'interviewSchedule'
          )}
          {renderField(
            'Extra Info',
            'Any other must-knows for this role that we might have missed?',
            <TextArea
              value={formData?.extraInfo}
              onValueChanged={handleChange('extraInfo')}
              autoResizeEnabled
              minHeight={'53px'}
              disabled={formData?.status}
            />,
            'extraInfo'
          )}
        </Section>

        {showButtons && (
          <div style={styles.buttonContainer}>
            <Button
              text="Submit"
              type="success"
              onClick={handleSubmit}
              style={styles.button}
              disabled={formData?.status ? true : !formChanged}
            />

            <Button
              text="Clear"
              type="normal"
              onClick={handleClear}
              style={styles.button}
              disabled={formData?.status ? true : !formChanged}
            />
          </div>
        )}

        {isLoading && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </div>
    );
  }
);

const Section = ({ title, children }) => (
  <div style={styles.section}>
    <h2 style={styles.sectionTitle}>{title}</h2>
    {children}
  </div>
);

const styles = {
  container: {
    margin: '0 auto',
    padding: '20px',
    maxWidth: '1500px'
  },
  header: {
    fontSize: '2em',
    marginBottom: '0.5em',
    textAlign: 'center'
  },
  subHeader: {
    fontSize: '1.2em',
    marginBottom: '1em',
    textAlign: 'center'
  },
  section: {
    marginBottom: '1.5em'
  },
  sectionTitle: {
    fontSize: '1.5em',
    borderBottom: '1px solid #ccc',
    paddingBottom: '0.5em',
    marginBottom: '0.5em'
  },
  field: {
    marginBottom: '1em',
    display: 'flex',
    alignItems: 'center'
  },
  label: {
    marginRight: '1em',
    fontWeight: 'bold',
    minWidth: '150px'
  },
  inputContainer: {
    flex: '1'
  },
  error: {
    color: 'red',
    fontSize: '0.9em',
    marginTop: '0.2em'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center', // Align buttons to the center
    gap: '10px',
    marginTop: '20px'
  },
  button: {
    minWidth: '100px',
    marginRight: '10px'
  }
};

export default BriefingTemplateForm;
