import React, { memo } from 'react';
import { Card, Col } from 'antd';
import FileUploader from 'devextreme-react/file-uploader';

const UploadTemplateCard = memo(({ handleFileChange, handleFileUpload }) => (
  <div
    style={{
      textAlign: 'center',
      padding: '20px',
      width: '500px',
      borderRadius: '0.75rem',
      boxShadow:
        'rgba(33, 33, 41, 0.1) 0rem 0rem 1rem 0rem, rgba(33, 33, 41, 0.06) 0rem 0.125rem 0.25rem -0.0625rem'
    }}
  >
    <h3 style={{ textAlign: 'center', justifyContent: 'center' }}>Upload Template</h3>
    <div style={{ paddingLeft: '50px' }}>
      <FileUploader
        multiple={false}
        accept=".xlsx"
        uploadMode="useButtons"
        onValueChanged={handleFileChange}
        uploadFile={handleFileUpload}
      />
    </div>
  </div>
));

export default UploadTemplateCard;
