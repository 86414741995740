import React, { useEffect } from 'react';
import Loadable from 'react-loadable';
import CustomSkelton from '../../../../components/Skelton/CustomSkelton';
import BaseLayOut from '../../../../components/Layout/BaseLayOut';
import useCapability from '../../../../utils/hooks/useCapability';
import Skelton from '../../../../components/Skelton/defaultSkelton';
const MasterCapabilityDataGrid = Loadable({
  loader: () => import('./MasterCapabilityDataGrid'),
  loading: () => <Skelton />
});

export default function MasterCapability({ routeKey, pageName }) {
  const {
    getAllMasterCapabilities,
    createUpdateMasterCapabilities,
    deleteMasterCapabilities,
    setMasterCapability,
    masterCapability,
    permissions,
    isLoading
  } = useCapability(routeKey);

  useEffect(() => {
    const fn = async () => await getAllMasterCapabilities();
    fn();

    return () => {
      setMasterCapability({ rows: [], columns: [] });
    };
  }, []);

  useEffect(() => {}, [masterCapability]);

  return (
    // <CustomSkelton is_loading={isLoading}>
    // <BaseLayOut pageTitle={pageName ?? 'Capability'}>
    <div className="__settings__container__right__section__">
      <MasterCapabilityDataGrid
        rows={masterCapability?.rows}
        columns={masterCapability?.columns}
        isLoading={isLoading}
        postAPI={createUpdateMasterCapabilities}
        handleDelete={deleteMasterCapabilities}
        permissions={permissions}
      />
    </div>
    //   </BaseLayOut>
    // </CustomSkelton>
  );
}
