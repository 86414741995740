import DataGrid, {
  AsyncRule,
  Column,
  Button,
  Export,
  SearchPanel,
  HeaderFilter,
  Paging,
  Pager,
  LoadPanel,
  Editing,
  RequiredRule,
  Lookup
} from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.light.css';
import { useEffect, useState } from 'react';
import { convertToLocalDateTime, createSanitizeAsyncRule } from '../../../utils/services/Helpers';
import { cloneIconClick } from '../../../utils/services/DatagridHelpers';
import { TagBox } from 'devextreme-react/tag-box';
import DetectNavigationBlocker from '../../../components/navigationdetector/DetectNavigationBlocker';
import { toast } from 'react-toastify';

export default function ModuleDataGrid({
  rows,
  columns,
  isLoading,
  permissions,
  dropDownData,
  postData
}) {
  const [dataSource, setDataSource] = useState([]);
  const [dataColumns, setDataColumns] = useState([]);
  const [hasDataChanged, setHasDataChanged] = useState(false);
  const sanitizeAsyncRule = createSanitizeAsyncRule("Invalid characters detected. Please remove any special characters.");

  useEffect(() => {
    setDataSource(rows);
    setDataColumns(columns);

    // cleanup on unmount
    return () => {
      setDataSource([]);
      setDataColumns([]);
    };
  }, []);

  useEffect(() => {
    setDataSource(rows);
  }, [rows]);

  useEffect(() => {
    setDataColumns(columns);
  }, [columns]);

  /**
   * @param col
   * function use to handle rendering of fields
   **/
  function renderField(dataColumns) {
    return dataColumns.map((col, index) => {
      if (col.type === 'select') {
        return (
          <Column
            key={index}
            editorOptions={{ dropDownOptions: { width: 'auto' } }}
            allowEditing={col.editable}
            visible={col.is_visible}
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            dataField={col.dataIndex}
            caption={col.title}
            setCellValue={function (rowData, value) {
              if (col.dataIndex === 'priority') rowData['tables'] = [];
              this.defaultSetCellValue(rowData, value);
            }}
          >
            {col.required ? <RequiredRule /> : null}
            <Lookup
              allowClearing
              dataSource={
                dropDownData && dropDownData.hasOwnProperty(col.dataIndex)
                  ? dropDownData[col.dataIndex]
                  : []
              }
              displayExpr="label"
              valueExpr="id"
            />
          </Column>
        );
      } else if (col.type === 'multi-select') {
        return (
          <Column
            key={index}
            width={'250'}
            editorOptions={{ dropDownOptions: { width: 'auto' } }}
            allowEditing={col.editable}
            visible={col.is_visible}
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            dataField={col.dataIndex}
            caption={col.title}
            editCellComponent={CustomDTagThisComp}
            cellTemplate={(container, options) => {
              const noBreakSpace = '\u00A0';
              const text = (options.value || [])
                .map((element) => options.column.lookup.calculateCellValue(element))
                .join(', ');
              container.textContent = text || noBreakSpace;
              container.title = text;
            }}
            calculateFilterExpression={function (filterValue, selectedFilterOperation, target) {
              if (target === 'search' && typeof filterValue === 'string') {
                return [col.dataIndex, 'contains', filterValue];
              }
              return function (data) {
                return (data[col.dataIndex] || []).indexOf(filterValue) !== -1;
              };
            }}
          >
            <Lookup
              allowClearing
              dataSource={
                dropDownData && dropDownData.hasOwnProperty(col.dataIndex)
                  ? dropDownData[col.dataIndex]
                  : null
              }
              displayExpr="label"
              valueExpr="id"
            />
            {col.required ? <RequiredRule /> : null}
          </Column>
        );
      } else if (col.type === 'actions') {
        return (
          <Column
            key={index}
            allowEditing={col.editable}
            visible={col.is_visible}
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            type="buttons"
            dataField={col.dataIndex}
            caption={col.title}
            fixed={false}
            width={'auto'}
          >
            <Button
              name="delete"
              icon={'trash'}
              visible={(e) => e && e.row && e.row.data && e.row.data.newRow === true}
            />
            <Button
              hint="Clone"
              icon="copy"
              visible={(e) => permissions && permissions.canCreate}
              onClick={(e) => cloneIconClick(e, dataSource, setDataSource)}
            />
          </Column>
        );
      } else if (col.type === 'date') {
        return (
          <Column
            key={index}
            dataField={col.dataIndex}
            cellComponent={(props) => <>{convertToLocalDateTime(props.data.data.createdAt)}</>}
            caption={col.title}
            alignment={'left'}
          />
        );
      } else if (col.type === 'string'){
        return (
          <Column
            key={index}
            dataField={col.dataIndex}
            caption={col.title}
            encodeHtml={col.hasOwnProperty('encodeHtml') ? col.encodeHtml : true}
            alignment={'left'}
          >
            <AsyncRule {...sanitizeAsyncRule} />
          </Column>
        );
      } else {
        return (
          <Column
            key={index}
            dataField={col.dataIndex}
            caption={col.title}
            encodeHtml={col.hasOwnProperty('encodeHtml') ? col.encodeHtml : true}
            alignment={'left'}
          />
        );
      }
    });
  }

  /**
   * @param props
   * custom component to display multi select box
   **/
  const CustomDTagThisComp = (props) => {
    useEffect(() => {}, [props]);

    function onValueChanged(e) {
      props.data.setValue(e.value);
    }

    function onSelectionChanged() {
      props.data.component.updateDimensions();
    }

    const ds =
      dropDownData &&
      dropDownData.hasOwnProperty(props.data.column.dataField) &&
      dropDownData[props.data.column.dataField]?.length
        ? dropDownData[props.data.column.dataField]
        : [];

    return (
      <TagBox
        dropDownOptions={{ width: 'auto' }}
        disabled={props.data.column.name && props.data.data.priority === 'ORG_STRUCTURE'}
        dataSource={ds}
        defaultValue={props.data.value}
        valueExpr="id"
        displayExpr={'label'}
        showSelectionControls
        maxDisplayedTags={3}
        showMultiTagOnly={false}
        applyValueMode="instantly"
        searchEnabled
        onValueChanged={onValueChanged}
        onSelectionChanged={onSelectionChanged}
      />
    );
  };

  /**
   * @param e
   * function use to prepare toolbar
   **/
  function onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      visible: permissions && permissions.canCreate,
      options: {
        icon: 'save',
        text: 'SUBMIT',
        disabled: !hasDataChanged,
        visible: permissions && permissions.canCreate,
        onClick: async () => {
          const d = [...dataSource];
          if (d.some((ds) => ds.priority !== 'ORG_STRUCTURE' && !ds?.tables?.length)) {
            toast.error('Some of you records are missing tables fields.');
          } else {
            await postData(dataSource);
            setHasDataChanged(false);
          }
        }
      }
    });
  }

  /**
   * @param e
   * Manage pre api call to save data and validation
   **/
  function onSave(e) {
    if (e && e.changes.length) {
      if (e.changes[0].type === 'remove') {
        const dsCopy = [...dataSource];
        const filteredDs = dsCopy.length ? dsCopy.filter((ds) => ds.id !== e.changes[0].key) : [];
        if (filteredDs && filteredDs.length) {
          setDataSource(filteredDs);
          setHasDataChanged(true);
        } else {
          setDataSource([]);
          setHasDataChanged(false);
        }
      } else {
        const dd = e.changes[0].data;
        let finalData = [];
        finalData.push(dd);
        let result = [];
        if (dataSource && dataSource.length) {
          result = _.unionBy(finalData, dataSource);
        } else result.push(dd);

        setDataSource(result);
        setHasDataChanged(true);
      }
    }
  }

  /**
   * @param e
   * function use perform operation when data-grid editor is not prepared e.g enable/disable field etc
   **/
  function onEditorPreparing(e) {
    if (e && e.dataField === 'name')
      e.editorOptions.disabled = !e?.row?.data.hasOwnProperty('newRow');

    if (e && e.dataField === 'tables')
      e.editorOptions.disabled = e.row.data.priority === 'ORG_STRUCTURE';
  }

  /**
   * @param e
   * validate row before saving
   **/
  function onRowValidating(e) {
    if (e && e.isValid) {
      if (e.newData) {
        const { name, newRow } = e.newData;
        if (newRow && !name) {
          e.isValid = false;
          e.errorText = 'Name is required.';
        }
      }
    }
  }

  /**
   * @param e
   * initialize new row in the data-grid
   **/
  const onInitNewRow = (e) => {
    e.data.newRow = true;
    e.data.priority = 'ORG_STRUCTURE';
  };

  return (
    <div id="data-grid-demo">
      <DataGrid
        onEditorPreparing={onEditorPreparing}
        onInitNewRow={onInitNewRow}
        onToolbarPreparing={onToolbarPreparing}
        onSaved={onSave}
        onRowValidating={onRowValidating}
        allowColumnResizing
        dataSource={dataSource}
        keyExpr="id"
        showBorders
      >
        <LoadPanel enabled={isLoading} visible={isLoading} />
        <HeaderFilter visible allowSearch />
        <SearchPanel visible />
        <Paging defaultPageSize={25} />
        <Pager visible showNavigationButtons showInfo displayMode={'full'} />
        <Export enabled allowExportSelectedData />
        <Editing
          mode="cell"
          allowUpdating={permissions && permissions.canCreate}
          allowAdding={permissions && permissions.canCreate}
          allowDeleting={permissions && permissions.canDelete}
        />
        {dataColumns && dataColumns.length > 0 ? renderField(dataColumns) : null}
        <Export enabled allowExportSelectedData />
      </DataGrid>
      <DetectNavigationBlocker
        setIsDataChanged={setHasDataChanged}
        isDataChanged={hasDataChanged}
      />
    </div>
  );
}
