import React, { useEffect, useContext } from 'react';
import Loadable from 'react-loadable';
import { Context } from '../../utils/context/store/Store';
import BaseLayOut from '../../components/Layout/BaseLayOut';
import useRequestType from '../../utils/hooks/useRequestType';
import usePagePermissions from '../../utils/hooks/usePagePermissions';
import useBulkUpload from 'utils/hooks/useBulkUpload';
import Skelton from '../../components/Skelton/defaultSkelton';
const HcTypeDataGrid = Loadable({
  loader: () => import('../manage-headcount-type/DataGrid/HcTypeDataGrid'),
  loading: () => <Skelton />
});

export default function ManageReqType({ routeKey, pageName }) {
  const { fetchRequestType, updateOrCreateRequestType, deleteRequestType, isLoading } =
    useRequestType(routeKey);
  const { bulkUpload } = useBulkUpload(routeKey);
  const [{ requestType }, dispatch] = useContext(Context);
  const { getPermissionsForPage } = usePagePermissions(routeKey);
  const permissions = getPermissionsForPage();

  useEffect(async () => {
    await fetchRequestType();
  }, []);

  return (
    // <BaseLayOut pageTitle={pageName ?? 'Manage Request Type'}>
    <div className="__body__section __base__layout__container __manage__segments__">
      <h3>Manage Request Type</h3>
      <HcTypeDataGrid
        columns={requestType && requestType.columns ? requestType.columns : []}
        rows={requestType && requestType.rows ? requestType.rows : []}
        isLoading={isLoading}
        permissions={permissions}
        hitApi={updateOrCreateRequestType}
        handleDelete={deleteRequestType}
        bulkUploadApi={bulkUpload}
        apiCallback={fetchRequestType}
        tableName={requestType?.tableName}
        uploadTemplateLink={requestType?.uploadTemplateLink}
      />
    </div>
    // </BaseLayOut>
  );
}
