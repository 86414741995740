import React, { useEffect } from 'react';
import Loadable from 'react-loadable';
import usePagePermissions from 'utils/hooks/usePagePermissions';
import BaseLayOut from '../../components/Layout/BaseLayOut';
import useMasterCVLightType from '../../utils/hooks/useMasterCVLightType';
import Skelton from '../../components/Skelton/defaultSkelton';
const ManageCVLightTypeDataGrid = Loadable({
  loader: () => import('./components/ManageCVLightTypeDataGrid'),
  loading: () => <Skelton />
});

function ManageCVLightType({ routeKey, pageName }) {
  const { getPermissionsForPage } = usePagePermissions(routeKey);
  const {
    getCVLightTypes,
    isLoading,
    updateOrCreateCVLightType,
    deleteCVLightType,
    cvLightTypes,
    setCVLightTypes
  } = useMasterCVLightType(routeKey);

  useEffect(() => {
    const fn = async () => await getCVLightTypes();
    fn();
  }, []);

  return (
    // <BaseLayOut pageTitle={pageName || 'Manage CV Light Types'}>
    <div className="__body__section __base__layout__container __manage__values__">
      <h3>Master CV Light Type</h3>
      <ManageCVLightTypeDataGrid
        isLoading={isLoading}
        rows={cvLightTypes.rows ?? []}
        columns={cvLightTypes.columns}
        permissions={getPermissionsForPage(routeKey)}
        dropDownData={cvLightTypes.dropdownValues}
        postData={updateOrCreateCVLightType}
        handleDelete={deleteCVLightType}
        allowSelection={true}
        allowAdding={true}
        allowDeletingFromApi={true}
        apiCallback={getCVLightTypes}
        setTypeState={setCVLightTypes}
        typeRecordSate={cvLightTypes}
      />
    </div>
    // </BaseLayOut>
  );
}

export default ManageCVLightType;
