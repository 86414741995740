import request from "../services/Http"
import { useContext, useState } from "react";
import { Context } from "../context/store/Store";
import { SET_IS_LOADING, SET_WORKFLOWS, SET_WORKFLOWS_COLUMNS, SET_WORKFLOW_STEP_TABLE_DATA } from "../context/store/Constants";
import { setColumnsForDataTable, isJSONValid, addKeyAndIndexFieldToGenericArrayOfObjects, getPageProperties, getTableColumns, isObjectEmpty, } from "../services/Helpers";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useIsLoading } from "./useIsLoading";
import useWorkflows from "./useWorkflows";

export default function useWorkflowStep(routeKey) {

    const [{ workflows, pagesAndPermissions, workflowStepTableData }, dispatch] = useContext(Context)
    const { getWorkflows } = useWorkflows("manage-workflow-access")
    const { isLoading, setIsLoading } = useIsLoading()
    const [records, setRecords] = useState(null)
    const [steps, setSteps] = useState({})

    async function getTableData() {
        setIsLoading(true)
        dispatch({ type: SET_IS_LOADING, payload: true })
        try {
            const res = await request.get('master-table/data')
            const data = { tableData: res.data.tableData, dropdownData: res.data.dropdownData, approverRoleData: res.data.approverRoleData, masterWeekdayId: res.data.masterWeekdayId }
            console.log('data', data)
            dispatch({ type: SET_WORKFLOW_STEP_TABLE_DATA, payload: data });
        }
        catch (e) { }
        dispatch({ type: SET_IS_LOADING, payload: false })
        setIsLoading(false)
    }

    async function getRecordByField(table, field) {
        setIsLoading(true)
        setRecords(null)
        try {
            const res = await request.get(`master-table/${table}/${field}`)
            let rows = isJSONValid(res.data.data) ?? {}
            setRecords(rows)
        }
        catch (e) { }
        setIsLoading(false)
    }

    async function getWorkflowStep() {
        setIsLoading(true)
        dispatch({ type: SET_IS_LOADING, payload: true })
        dispatch({
            type: SET_WORKFLOWS,
            payload: []
        });
        try {
            const res = await request.get('workflow-step')
            const properties = getPageProperties(pagesAndPermissions, routeKey)
            const table = getTableColumns(properties.page.table, 'workflow-step')
            let rows = isJSONValid(res.data.data) ?? {}
            let newRowObject = isJSONValid(table.row.defaultObj) ?? {}
            let dropdownValues = isJSONValid(res.data.dropdownValues) ?? {}

            if (workflows && workflows.hasOwnProperty('apiDelete') && workflows.apiDelete) {
                rows = [...workflows.rows, ...rows]
            }

            if (data_by === "workflow") {
                rows.forEach((row) => {
                    row.workflow_accesses.count = row.workflow_accesses.length
                })
            }

            const dataTableData = { columns: setColumnsForDataTable(columns, dropdownValues), rows: addKeyAndIndexFieldToGenericArrayOfObjects(rows), newRowObject: newRowObject, dropdownValues: dropdownValues, apiDelete: false }

            dispatch({
                type: SET_WORKFLOWS_COLUMNS,
                payload: setColumnsForDataTable(columns, dropdownValues)
            });
            dispatch({
                type: SET_WORKFLOWS,
                payload: dataTableData
            });
        }
        catch (e) { }
        setIsLoading(false)
        dispatch({ type: SET_IS_LOADING, payload: false })
    }

    async function getStepsByWorkflow(workflow) {
        setIsLoading(true)
        try {
            const res = await request.get(`headcount/workflow/${workflow}/step`)
            if (res) {
                let columns = res.data.object.columns
                let rows = res.data.data
                let newRowObject = isJSONValid(res.data.object.row.defaultObj) ?? {}
                let dropdownValues = isJSONValid(res.data.dropdownValues) ?? {}

                // if (new_approvals && new_approvals.hasOwnProperty('apiDelete') && new_approvals.apiDelete) {
                //     rows = [...new_approvals.rows, ...rows]
                // }
                rows.forEach((row) => {
                    if (!row.hasOwnProperty('index') && !row.hasOwnProperty('key')) {
                        row.index = row.sequence
                        row.key = row.sequence
                    }
                })

                const dataTableData = { columns: columns, rows: rows, newRowObject: newRowObject, dropdownValues: dropdownValues, apiDelete: false }
                setSteps(dataTableData)
                setIsLoading(false)
            }
        }
        catch (e) { }
        setIsLoading(false)

    }

    async function updateOrCreateWorkflowStep(data, val) {
        setIsLoading(true)
        dispatch({ type: SET_IS_LOADING, payload: true })
        try {
            const res = await request.post(val, { workflows: data })
            toast.success(res.data.data)
            await getWorkflows(val)
        }
        catch (e) { }
        setIsLoading(false)
        dispatch({ type: SET_IS_LOADING, payload: false })
    }

    async function deleteWorkflowSteps(steps, workflowId = null) {
        setIsLoading(true)
        try {
            const res = await request.delete('workflow-step', {
                data: {
                    workflowId: workflowId,
                    steps: steps
                }
            })
            await getWorkflows("workflow")
            // await getApprovalsByWorkflow(workflowId)
            toast.success(res.data.data)
        }
        catch (e) { }
        setIsLoading(false)
    }


    return { getTableData, getRecordByField, getWorkflowStep, getStepsByWorkflow, updateOrCreateWorkflowStep, deleteWorkflowSteps, isLoading, records, steps }
}