import React, { memo } from 'react';
import { Link } from '@mui/material';
import { Card, Col } from 'antd';

const style = {
  padding: '10px',
  width: '240px',
  borderRadius: '0.75rem',
  boxShadow:
    'rgba(33, 33, 41, 0.1) 0rem 0rem 1rem 0rem, rgba(33, 33, 41, 0.06) 0rem 0.125rem 0.25rem -0.0625rem'
};

const DownloadTemplateCard = memo(({ downloadLink, title }) => (
  <a className='__default__button__layout' href={downloadLink} target="_blank">
    <div style={style}>
      <h3 className='__mb__0' style={{ textAlign: 'center' }}>{title}</h3>
    </div>
  </a>
));

export default DownloadTemplateCard;
