import React from 'react';

const QueueDisplay = ({ message }) => {
  return (
    <div style={styles.banner}>
      <div style={styles.content}>
        <div style={styles.message}>
          {
            message && message.trim() ? (
            <>
              <span style={styles.highlight}>{message}</span>
            </>
          ) : null
          }
        </div>
      </div>
    </div>
  );
};

const styles = {
  banner: {
    width: '100%',
    backgroundColor: '#f9fbfc', // Neutral light background
    color: '#333333', // Dark gray text for readability
    padding: '10px 15px', // Compact padding
    borderRadius: '8px', // Slightly rounded edges for modern design
    border: '1px solid #d3d9de', // Subtle border for structure
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)', // Light shadow for depth
    fontFamily: "'Poppins', sans-serif",
    // textAlign: 'center', // Centered text
    margin: '0px 0px 10px 0px', // Spacing around the banner
    // maxWidth: '800px', // Restrict width for readability
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center', // Center content
    // justifyContent: 'center',
  },
  message: {
    fontSize: '1rem', // Standard font size for subtle info
    fontWeight: '400',
    lineHeight: '1.5',
    color: '#333333', // Matches text color for consistency
  },
  highlight: {
    color: '#0056b3', // Professional blue for emphasis
    fontWeight: '600',
  },
};

export default QueueDisplay;