import React, { useContext, useEffect } from 'react';
import Loadable from 'react-loadable';
import { Context } from 'utils/context/store/Store';
import useRoles from 'utils/hooks/useRoles';
import BaseLayOut from '../../components/Layout/BaseLayOut';
import usePagePermissions from '../../utils/hooks/usePagePermissions';
import Skelton from '../../components/Skelton/defaultSkelton';
const FunctionalAccessDataGrid = Loadable({
  loader: () => import('./DataTable/FunctionalAccessDataGrid'),
  loading: () => <Skelton />
});

function ManageRoles({ routeKey, pageName }) {
  const [{ roles }, dispatch] = useContext(Context);
  const { getPermissionsForPage } = usePagePermissions(routeKey);
  const { getRoles, deleteRoles, updateOrCreateRoles, isLoading } = useRoles(routeKey);

  useEffect(async () => {
    await getRoles();
  }, []);

  return (
    // <BaseLayOut pageTitle={pageName ?? 'Manage Functional Security'}>
    <div className="__settings__container__right__section__">
      <FunctionalAccessDataGrid
        loading={isLoading}
        columns={roles.columns}
        rows={roles.rows}
        dropDownData={roles.dropdownValues}
        objToAdd={roles.newRowObject}
        postData={updateOrCreateRoles}
        handleDelete={deleteRoles}
        isRoles={true}
        routeKey={routeKey}
        permissions={getPermissionsForPage()}
        allowSelection={true}
        allowAdding={true}
        allowDeleting={true}
        allowUpdating={true}
      />
    </div>
    // </BaseLayOut>
  );
}

export default ManageRoles;
