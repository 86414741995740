import React, { useContext, useEffect } from 'react';
import Loadable from 'react-loadable';
import usePagePermissions from '../../utils/hooks/usePagePermissions';
import BaseLayOut from '../../components/Layout/BaseLayOut';
import useManageLights from '../../utils/hooks/useManageLights';
import { Context } from '../../utils/context/store/Store';
import useBulkUpload from '../../utils/hooks/useBulkUpload';
import Skelton from '../../components/Skelton/defaultSkelton';
const ManageLightsGrid = Loadable({
  loader: () => import('./components/manage-lights-grid'),
  loading: () => <Skelton />
});

function ManageLights({ routeKey, pageName }) {
  const { getPermissionsForPage } = usePagePermissions(routeKey);
  const { getCVLights, isLoading, updateOrCreateCVLight, deleteCVLight } =
    useManageLights(routeKey);
  const { bulkUpload } = useBulkUpload(routeKey);
  const [{ lights }] = useContext(Context);

  const getLights = async () => {
    await getCVLights();
  };

  useEffect(() => {
    getLights();
  }, []);

  return (
    // <BaseLayOut pageTitle={pageName || "Manage Lights"}>
    <div className="__body__section __base__layout__container __manage__values__">
      <h3>Manage Lights</h3>
      <ManageLightsGrid
        isLoading={isLoading}
        rows={lights.rows ?? []}
        columns={lights.columns}
        permissions={getPermissionsForPage(routeKey)}
        dropDownData={lights.dropdownValues}
        postData={updateOrCreateCVLight}
        handleDelete={deleteCVLight}
        allowSelection
        allowAdding
        allowDeletingFromApi
        bulkUploadApi={bulkUpload}
        apiCallback={getCVLights}
        tableName={lights.tableName}
        uploadTemplateLink={lights.uploadTemplateLink}
        orgStructureLink={lights.orgStructureLink}
      />
    </div>
    // </BaseLayOut>
  );
}

export default ManageLights;
