import { useContext, useState } from 'react';
import { useIsLoading } from './useIsLoading';
import { Context } from '../context/store/Store';
import { SET_IS_LOADING } from '../context/store/Constants';
import request from '../services/Http';
import { generateFileData, getPageProperties, getTableColumns } from '../services/Helpers';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export default function useExitManagement(routeKey) {
  const [exitDetails, setExitDetails] = useState({});
  const [empExitDetails, setEmpExitDetails] = useState({});
  const { isLoading, setIsLoading } = useIsLoading()
  const [{ pagesAndPermissions }, dispatch] = useContext(Context)
  const navigate = useNavigate()

  async function getAllExitRecords() {
    setIsLoading(true)
    dispatch({ type: SET_IS_LOADING, payload: true })
    try {
      const res = await request.get('exit-management')
      if (res) {
        const properties = getPageProperties(pagesAndPermissions, routeKey)
        const table = getTableColumns(properties.page.table, routeKey)
        let columns = table.columns
        let rows = res.data.data
        let masterEmployeeDetail = res.data.masterEmployeeDetail
        let canUserCreate = res.data.canUserCreate
        setExitDetails({ columns, rows, masterEmployeeDetail, canUserCreate })
      }
    }
    catch (e) { }
    finally {
      setIsLoading(false)
      dispatch({ type: SET_IS_LOADING, payload: false })
    }
  }

  async function getEmployeeExitRecord(transactionExitId) {
    dispatch({ type: SET_IS_LOADING, payload: true })
    setIsLoading(true)
    try {
      const url = transactionExitId ? `exit-management/emp?transactionExitId=${transactionExitId}` : 'exit-management/emp'
      const res = await request.get(url)
      if (res) {
        // const properties = getPageProperties(pagesAndPermissions, routeKey)
        // const table = getTableColumns(properties.page.table, routeKey)
        // let columns = table.columns
        let rows = res.data.data[0] ?? {}
        let masterEmployeeDetail = res.data.masterEmployeeDetail
        let dropdownData = res.data.dropdownData
        setEmpExitDetails({ rows, masterEmployeeDetail, dropdownData })
      }
    }
    catch (e) { }
    finally {
      setIsLoading(false)
      dispatch({ type: SET_IS_LOADING, payload: false })
    }
  }

  async function createOrUpdateExitRecords(data) {
    dispatch({ type: SET_IS_LOADING, payload: true })
    setIsLoading(true)
    try {
      const exitFormData = new FormData();
      const exitWithAttachment = [data].filter(l => l.hasOwnProperty('attachment') && l?.attachment !== null) ?? []
      const body = { ...data, ...exitDetails.masterEmployeeDetail }
      exitFormData.append('data', JSON.stringify(body))
      const { fileDetails, attachments } = await generateFileData(exitWithAttachment, "transaction_exit", 'attachment')
      exitFormData.append('fileDetails', JSON.stringify(fileDetails))

      for (const a of attachments) {
        if (a instanceof File) exitFormData.append('attachment', a)
      }
      const res = await request.post('exit-management', exitFormData, { headers: { 'Content-Type': 'multipart/form-data' } })
      if (res) {
        toast.success(res.data.data)
        navigate('/views/manage-exits')
      }
    } catch (e) { }
    finally {
      setIsLoading(false)
      dispatch({ type: SET_IS_LOADING, payload: false })
    }

  }

  async function cancelExitRequest(data, comments) {
    setIsLoading(true)
    dispatch({ type: SET_IS_LOADING, payload: true })
    try {
      const res = await request.post('exit-management/cancel', { data, comments })
      if (res) {
        toast.success(res.data.data)
        navigate('/views/manage-exits')
      }
    } catch (e) { }
    finally {
      setIsLoading(false)
      dispatch({ type: SET_IS_LOADING, payload: false })
    }

  }

  return {
    getAllExitRecords, getEmployeeExitRecord, createOrUpdateExitRecords, cancelExitRequest, isLoading, exitDetails, empExitDetails
  }
}