import React, { useEffect } from 'react';
import Loadable from 'react-loadable';
import BaseLayOut from '../../components/Layout/BaseLayOut';
import useFreelancerManagement from '../../utils/hooks/useFreelancerManagement';
import usePagePermissions from '../../utils/hooks/usePagePermissions';
import Skelton from '../../components/Skelton/defaultSkelton';
const FreelancerManagementDataGrid = Loadable({
  loader: () => import('./DataTable/FreelancerManagementDataGrid'),
  loading: () => <Skelton />
});

export default function FreelancerManagement({ routeKey, pageName }) {
  const { getAllFreelancers, freelancers, isLoading } = useFreelancerManagement(routeKey);
  const { getPermissionsForPage } = usePagePermissions(routeKey);

  useEffect(() => {
    const fn = async () => await getAllFreelancers();
    fn();
  }, []);

  useEffect(() => {}, [freelancers, isLoading]);

  return (
    // <BaseLayOut pageTitle={pageName}>
    <div className="__body__section __base__layout__container __manage__segments__">
      <h3>Manage Freelancers</h3>
      <FreelancerManagementDataGrid
        rows={freelancers?.rows}
        columns={freelancers?.columns}
        hcDetailColumns={freelancers?.hcDetailColumns}
        isLoading={isLoading}
        permissions={getPermissionsForPage()}
      />
    </div>
    // </BaseLayOut>
  );
}
