import React, { useContext, useEffect, useState, useCallback } from 'react';
import Loadable from 'react-loadable';
import { Context } from 'utils/context/store/Store';
import { SelectBox } from 'devextreme-react/select-box';
import useAttributeManagement from 'utils/hooks/useAttributeManagement';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
import Card from '@mui/material/Card';
import usePagePermissions from 'utils/hooks/usePagePermissions';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import Select from 'react-select';
import { Grid } from '@mui/material';
import { selectCustomStyles, a11yProps } from '../../utils/services/Helpers';
import useBulkUpload from 'utils/hooks/useBulkUpload';
import Skelton from '../../components/Skelton/defaultSkelton';
import useFinancialAttributeManagement from 'utils/hooks/useFinancialAttributeManagement';
const AttributeManagementDataGrid = Loadable({
  loader: () => import('./DataTable/AttributeManagementDataGrid'),
  loading: () => <Skelton />
});

function FinancialAttributeManagement({ routeKey, pageName }) {
  const { getFinancialAttributeManagementList, getFinancialAttributeManagement, updateOrCreateFinancialAttributeManagement, deleteFinancialAttributeManagement, isLoading } = useFinancialAttributeManagement(routeKey)
  const { bulkUpload } = useBulkUpload(routeKey);
  const [{ financialAttribute, financialAttributeList }, dispatch] = useContext(Context);
  const { getPermissionsForPage } = usePagePermissions(routeKey);

  const [value, setValue] = useState(null);
  const [displayValue, setDisplayValue] = useState(null);

  const tablesForBulkUpload = [];

  useEffect(async () => {
    await getFinancialAttributeManagementList();
    if (value !== null) await getFinancialAttributeManagement(value);
  }, []);

  useEffect(async () => {
    if (value !== null) await getFinancialAttributeManagement(value);
  }, [value]);

  const selectBoxHandle = useCallback(
    (e) => {
      if (e.event !== undefined) {
        setValue(e.value);
        const indexInArray = financialAttributeList?.findIndex(
          (eFindIndex) => eFindIndex.value === e.value
        );
        indexInArray !== -1 && setDisplayValue(financialAttributeList?.[indexInArray].label);
      }
    },
    [financialAttributeList, value, displayValue, setValue, setDisplayValue]
  );

  return (
    <div className="__body__section __manage__values__">
      <div className='__base__layout__container'>
        <SelectBox
          width={300}
          placeholder={'Please select option... '}
          dataSource={financialAttributeList}
          value={value ?? null}
          searchEnabled={true}
          showClearButton={true}
          valueExpr="value"
          displayExpr="label"
          onValueChanged={(e) => selectBoxHandle(e)}
          className={`__select__box__ __mt__15`}
        />
        {value ? (
          <>
            <br />
            <h4>Manage {displayValue}</h4>
            <AttributeManagementDataGrid
              routeKey={routeKey}
              isLoading={isLoading}
              rows={financialAttribute.rows ?? []}
              columns={financialAttribute.columns}
              permissions={getPermissionsForPage(routeKey)}
              dropDownData={financialAttribute.dropdownValues}
              postData={updateOrCreateFinancialAttributeManagement}
              handleDelete={deleteFinancialAttributeManagement}
              valueToFetch={value}
              allowSelection={true}
              allowAdding={true}
              allowDeletingFromApi={value !== 'business-category-hfm-group-client' && value !== 'business-category-hfm-group-client-restatement'}
              bulkUploadApi={bulkUpload}
              apiCallback={getFinancialAttributeManagement}
              tableName={financialAttribute.tableName}
              uploadTemplateLink={financialAttribute.uploadTemplateLink}
              orgStructureLink={financialAttribute.orgStructureLink}
              allowBulkUploading={tablesForBulkUpload.indexOf(value) !== -1}
            />
          </>
        ) : null}
      </div>
    </div>
  );
}

export default FinancialAttributeManagement;
