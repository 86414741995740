import React, { useState, useEffect, useContext } from 'react';
import { useIsLoading } from './useIsLoading';
import request from '../services/Http';
import 'react-toastify/dist/ReactToastify.css';
import Handsontable from 'handsontable';
import {
  ODD_ROW_CLASS,
  handleActionsEnum,
} from '../../views/financial-forecast/components/constants';
import { getUserInfo, isJSONValid } from 'utils/services/Helpers';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { SocketContext } from '../context/socketContext';
import useApprovals from './useApprovals';
import { SET_IS_LOADING } from 'utils/context/store/Constants';
import { Context } from 'utils/context/store/Store';

export default function useFinancialForecast(isForApprover = false) {
  const headerAlignments = new Map([
    ['9', 'htCenter'],
    ['10', 'htRight'],
    ['12', 'htCenter']
  ]);
  const API_END_POINT = 'fc-header';
  const API_FF_Structure = 'transaction-financial-forecast';
  const { setIsLoading, isLoading } = useIsLoading();
  const [renderTable, setRenderTable] = React.useState(false);
  const [headers, setHeaders] = useState(null);
  const [dataSource, setDataSource] = useState(null);
  const [FFStructureToDisplayWithData, setFFStructureToDisplayWithData] = useState(null);
  const [FFStructureToDisplayForHeader, setFFStructureToDisplayForHeader] = useState(null);
  const [dropdownData, setDropdownData] = useState(null)
  const [cyclePeriod, setCyclePeriod] = useState(null);
  const [hfm, setHfm] = useState(null);
  const [businessCategory, setBusinessCategory] = useState(null);
  const [client, setClient] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [year1, setYear1] = useState(null);
  const [year2, setYear2] = useState(null);
  const [version1, setVersion1] = useState(null);
  const [version2, setVersion2] = useState(null);
  const [selectedView, setSelectedView] = useState('single');
  const [data, setData] = useState([])
  const [objectForApproval, setObjectForApproval] = useState([])
  const [sheetTwo, setSheetTwo] = useState(null)
  const [isSheetSubmitted, setIsSheetSubmitted] = useState(false)
  const [hasSheetEdited, setHasSheetEdited] = useState(false)
  const [canSubmitSheet, setCanSubmitSheet] = useState(false)
  const [canActionSheet, setCanActionSheet] = useState(false)
  const [canApproverEdit, setCanApproverEdit] = useState(false)
  const [canEdit, setCanEdit] = useState(false);
  const [sheetEdit, setSheetEdit] = useState(false);
  const [queueMessage, setQueueMessage] = useState(null);
  const socket = useContext(SocketContext);
  const [{ }, dispatch] = useContext(Context)
  const {
    bulkRollback,
    approveOrReject,
  } = useApprovals();

  useEffect(() => setIsLoading(false), []);
  useEffect(() => {
    const fetchData = async () => {
      await getDataForTable();
    };
    fetchData();
  }, [hfm, selectedView, client, year1, year2, version1, version2]);
  useEffect(() => {
    if (client === null && !isForApprover) {
      setHeaders(null)
      setRenderTable(true);
      setDataSource(null);
    }
    resetSettings();
  }, [cyclePeriod, businessCategory, hfm, client]);
  useEffect(() => {
    if (year2 && year1) {
      // setHeaders(__ffData.headers.forecast);
      // setDataSource(__ffData.dataSource.forecast);
      // setRenderTable(true);
      const header = isJSONValid(FFStructureToDisplayForHeader?.[0]?.header);
      setHeaders(header?.header);
      setDataSource(FFStructureToDisplayWithData);
      setRenderTable(true);
    }
    else if (year1) {
      // setHeaders(__ffData.headers.history);
      const header = isJSONValid(FFStructureToDisplayForHeader[0].header);
      setHeaders(header?.header);
      // setDataSource(__ffData.dataSource.history);
      setDataSource(FFStructureToDisplayWithData);
      setRenderTable(true);
    }
  }, [year2]);
  useEffect(() => {
    // Check if the data is available
    if (FFStructureToDisplayForHeader && FFStructureToDisplayForHeader.length > 0) {
      const header = isJSONValid(FFStructureToDisplayForHeader[0]?.header);
      setHeaders(header?.header);
      setDataSource(FFStructureToDisplayWithData);

      // Set renderTable to true only after data is set
      setRenderTable(true);
    }
  }, [FFStructureToDisplayForHeader]);
  useEffect(() => { }, [queueMessage, canEdit, sheetEdit]);

  const resetSettings = () => {
    if (hfm === null || client === null || year1 === null) {
      setRenderTable(false);
      handleAction(handleActionsEnum.YEAR_1);
      handleAction(handleActionsEnum.YEAR_2);
    }
  };

  const addClassesToRows = (TD, row, column, prop, value, cellProperties) => {
    // Adding classes to `TR` just while rendering first visible `TD` element
    if (column !== 0) {
      return;
    }

    const parentElement = TD.parentElement;

    if (parentElement === null) {
      return;
    }

    // Add class to odd TRs
    if (row % 2 === 0) {
      Handsontable.dom.addClass(parentElement, ODD_ROW_CLASS);
    } else {
      Handsontable.dom.removeClass(parentElement, ODD_ROW_CLASS);
    }
  };

  const drawCheckboxInRowHeaders = function drawCheckboxInRowHeaders(row, TH) {
    const input = document.createElement('input');

    input.type = 'checkbox';

    if (row >= 0 && this.getDataAtRowProp(row, '0')) {
      input.checked = true;
    }

    Handsontable.dom.empty(TH);

    TH.appendChild(input);
  };

  const changeCheckboxCell = function changeCheckboxCell(event, coords) {
    const target = event.target;

    if (coords.col === -1 && event.target && target.nodeName === 'INPUT') {
      event.preventDefault(); // Handsontable will render checked/unchecked checkbox by it own.

      this.setDataAtRowProp(coords.row, '0', !target.checked);
    }
  };

  const alignHeaders = (e, column, TH) => {
    if (column < 0) {
      return;
    }

    if (TH.firstChild) {
      const alignmentClass = e.isRtl() ? 'htRight' : 'htLeft';

      if (headerAlignments.has(column.toString())) {
        Handsontable.dom.removeClass(TH.firstChild, alignmentClass);
        Handsontable.dom.addClass(TH.firstChild, headerAlignments.get(column.toString()));
      } else {
        Handsontable.dom.addClass(TH.firstChild, alignmentClass);
      }
    }
  };

  const getSetupHeader = async () => {
    setIsLoading(true);
    dispatch({ type: SET_IS_LOADING, payload: true });
    try {
      const res = await request.get(API_END_POINT);
      if (res) {
        // toast.success(res.data.data);
        return res.data;
      }
    } catch (e) {
    } finally {
      dispatch({ type: SET_IS_LOADING, payload: false });
      setIsLoading(false);
    }
  };

  const getDropdownData = async () => {
    setIsLoading(true);
    dispatch({ type: SET_IS_LOADING, payload: true });
    try {
      const res = await request.get(`${API_FF_Structure}/dd-data`);
      setDropdownData(prevState => ({ ...prevState, ...res?.data?.dropdownData ?? {} }));
      return dropdownData;
    } catch (e) {
      console.error('Error fetching dropdown data:', e);
    } finally {
      dispatch({ type: SET_IS_LOADING, payload: false });
      setIsLoading(false);
    }
  };

  const buildQueryParams = () => {
    const params = [
      businessCategory && `masterBusinessCategoryId=${businessCategory}`,
      !isForApprover && `masterOrgHFMId=${hfm}`,
      !isForApprover && `clientGroupId=${client}`,
      isForApprover && `consolidated=${true}`,
      `masterBudgetCyclePeriodId=${cyclePeriod}`,
      `masterRFHeaderId=${dropdownData?.masterBudgetCyclePeriodId?.find(h => h.id === cyclePeriod)?.masterRFHeaderId}`,
      year1 && `year1=${year1}`,
      year2 && `year2=${year2}`,
      version1 && `version1=${version1}`,
      version2 && `version2=${version2}`,
    ].filter(Boolean).join('&');

    return `?${params}`;
  };

  const getDataForTable = async () => {
    if (!isForApprover && (!businessCategory || !hfm || !client)) return; // Ensure the required parameters are available
    if (isForApprover && (!cyclePeriod || !hfm?.length)) return;

    setIsLoading(true);
    dispatch({ type: SET_IS_LOADING, payload: true });
    try {
      const res = isForApprover ? await request.post(`${API_FF_Structure}/approver${buildQueryParams()}`, {
        masterOrgHFMId: hfm,
        clientGroupId: client,
      }) : await request.get(`${API_FF_Structure}${buildQueryParams()}`);
      if (res) {
        setDropdownData(prvState => {
          return { ...prvState, ...res.data.dropdownData }
        });
        setFFStructureToDisplayWithData(res?.data?.data);
        setFFStructureToDisplayForHeader(res?.data?.headers);
        setIsSheetSubmitted(res.data.isSubmitted)
        setData(res.data.savedTransactions)
        setCanSubmitSheet(res.data.canSubmit)
        setCanActionSheet(res.data.needApproval)
        setObjectForApproval(res.data.objectForApproval)
        setCanApproverEdit(res.data.canApproverEdit)
        setHeaders(dropdownData?.masterBudgetCyclePeriodId.find(c => c.id === cyclePeriod)?.header)
        setDataSource(res?.data?.data);
        setRenderTable(true);
        if (res.data?.sheet2) setSheetTwo(res.data?.sheet2)
        return res.data;
      }
    } catch (e) {
      console.error('Error fetching table data:', e);
    } finally {
      dispatch({ type: SET_IS_LOADING, payload: false });
      setIsLoading(false);
    }
  };

  const submitHeader = async (body) => {
    setIsLoading(true);
    dispatch({ type: SET_IS_LOADING, payload: true });
    try {
      const res = await request.post(API_END_POINT, body);
      if (res) {
        toast.success(res.data.data);
      }
    } catch (e) {
    } finally {
      dispatch({ type: SET_IS_LOADING, payload: false });
      setIsLoading(false);
    }
  };

  const removeHeaderVersion = async (idx) => {
    setIsLoading(true);
    dispatch({ type: SET_IS_LOADING, payload: true });
    try {
      const res = await request.delete(API_END_POINT, { data: { headerIds: idx } });
      if (res) {
        toast.success(res.data);
      }
    } catch (e) {
    } finally {
      dispatch({ type: SET_IS_LOADING, payload: false });
      setIsLoading(false);
    }
  };

  const saveAsExcel = (hotInstance) => {
    try {
      const exportPlugin = hotInstance?.getPlugin('exportFile');
      exportPlugin?.downloadFile('csv', {
        bom: false,
        columnDelimiter: ',',
        columnHeaders: false,
        exportHiddenColumns: false,
        exportHiddenRows: false,
        fileExtension: 'csv',
        filename: `RollingForecast_${new Date().toLocaleDateString()}`,
        mimeType: 'text/csv',
        rowDelimiter: '\r\n',
        rowHeaders: true,
      });
    }
    catch (e) {
      console.error('error in exporting excel file', e)
    }
  };

  const handleAction = (type = null, value = null) => {
    switch (type) {
      case handleActionsEnum.CYCLE_PERIOD:
        setRenderTable(false);
        setCyclePeriod(value);
        setBusinessCategory(null);
        setHfm(null);
        setClient(null);
        setYear1(null);
        break;
      case handleActionsEnum.BUSINESS_CATEGORY:
        setRenderTable(false);
        setBusinessCategory(value);
        setHfm(null);
        setClient(null);
        setYear1(null);
        break;
      case handleActionsEnum.HFM:
        setRenderTable(false);
        setHfm(value);
        if (isForApprover) setRenderTable(true)
        setClient(null);
        setYear1(null);
        break;
      case handleActionsEnum.CLIENT:
        setClient(value);
        if (isForApprover) setRenderTable(true)
        setYear1(null);
        break;
      case handleActionsEnum.CURRENCY:
        setCurrency(value);
        break;
      case handleActionsEnum.YEAR_1:
        setYear1(value);
        setRenderTable(true)
        break;
      case handleActionsEnum.YEAR_2:
        setYear2(value);
        break;
      case handleActionsEnum.VERSION_1:
        setVersion1(value);
        break;
      case handleActionsEnum.VERSION_2:
        setVersion2(value);
        break;
      case handleActionsEnum.SHEET_EDIT:
        setSheetEdit(value);
        if (value === true) {
          setQueueMessage(null);
          socket.emit('handle-rf-page', {
            userId: getUserInfo()?.id, payload:
            {
              masterBudgetCyclePeriodId: cyclePeriod,
              masterBusinessCategoryId: businessCategory,
              masterOrgHFMId: hfm,
              clientGroupId: client,
              masterRFAgencyId: dropdownData?.masterOrgHFMId?.find(h => h.masterBusinessCategoryId === businessCategory && h.id === hfm)?.master_rf_agency?.id,
            }
          });
          setRenderTable(true);
        }
        break;
      default:
        setCyclePeriod(value);
        setBusinessCategory(value);
        setHfm(value);
        setClient(value);
        setCurrency(value);
        setYear1(value);
        setYear2(value);
        setVersion1(value);
        setVersion2(value);
        break;
    }
  };

  const submitSheet = async () => {
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "You'll not be able to edit the sheet.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Submit it!",
        heightAuto: false,
        height: "200px",
        customClass: {
          container: '__swal__continer__ __font__family__regular',
          confirmButton: '__default__button__layout __btn__green __primary__color __unset__border',
          cancelButton: '__default__button__layout __primary__color __unset__border'
        }
      })
        .then(async (res) => {
          if (res.isConfirmed) {
            data?.map(d => {
              d.isSubmitted = true
              d.isDraft = false
              return d
            })
            await createUpdateTransactions(data);
          }
        })
    }
    catch (e) { }
  }

  const rollbackSheet = async () => {
    try {
      await bulkRollback(
        processObjectForPostApi(data, true),
        'transaction_rf_group',
        8,
        null,
        resetStateAfterApi
      );
    }
    catch (e) {
      console.log('error', e)
    }
  }

  const approveSheet = async () => {
    try {
      data?.map(d => {
        d.isSubmitted = true
        d.isDraft = false
        return d
      })
      await approveOrReject(
        true,
        processObjectForPostApi(data, true),
        'transaction_rf_group',
        8,
        null,
        resetStateAfterApi
      );
    }
    catch (e) {
      console.log('error', e)
    }
  }

  const save = async () => {
    try {
      data?.map(d => d.isDraft = true)
      await createUpdateTransactions(data)
    }
    catch (e) { }
  };

  const resetStateAfterApi = async (res = null) => {
    setSheetEdit(false)
    setCanEdit(false)
    setQueueMessage("Thank you for your quick submission 👍😊.")
    setHasSheetEdited(false)
    if (res) toast.success(res.data.data)
    await Promise.all([
      getDataForTable(),
      getDropdownData(),
    ])
  }

  const processObjectForPostApi = (data, isForAction = false) => {
    data?.map(d => {
      d.year = year1
      delete d['grandparent']
      delete d['parent']
      delete d['child']

      return d
    })
    let finalData = isForAction
      ? objectForApproval
      : {
        masterBudgetCyclePeriodId: cyclePeriod,
        masterBusinessCategoryId: businessCategory,
        masterOrgHFMId: hfm,
        clientGroupId: client,
        masterRFAgencyId: dropdownData?.masterOrgHFMId?.find(h => h.masterBusinessCategoryId === businessCategory && h.id === hfm)?.master_rf_agency?.id,
        masterRFHeaderId: dropdownData?.masterBudgetCyclePeriodId?.find(h => h.id === cyclePeriod)?.masterRFHeaderId,
        transactions: data
      }

    return finalData
  }

  const createUpdateTransactions = async (data) => {
    setIsLoading(true);
    dispatch({ type: SET_IS_LOADING, payload: true });
    try {
      const res = await request.post(API_FF_Structure, processObjectForPostApi(data))
      if (res) {
        await resetStateAfterApi(res)
      }
    }
    catch (e) { }
    finally {
      dispatch({ type: SET_IS_LOADING, payload: false });
      setIsLoading(false)
    }
  }

  return {
    hfm, client, currency, year1, year2,
    version1, version2, headers, renderTable,
    dataSource, isLoading, setIsLoading,
    changeCheckboxCell, alignHeaders,
    drawCheckboxInRowHeaders, addClassesToRows,
    saveAsExcel, handleAction, submitHeader,
    getSetupHeader, removeHeaderVersion, setDataSource,
    getDropdownData, getDataForTable, approveSheet, rollbackSheet,
    save, submitSheet, dropdownData, setData, data, canApproverEdit,
    selectedView, setSelectedView, sheetTwo, isSheetSubmitted, canActionSheet,
    hasSheetEdited, setHasSheetEdited, businessCategory, canSubmitSheet, cyclePeriod, objectForApproval,
    canEdit, setCanEdit, queueMessage, setQueueMessage, socket, setSheetEdit, sheetEdit, setDropdownData
  };
}
