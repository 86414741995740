import React, { useEffect, useContext, useState } from 'react';
import Loadable from 'react-loadable';
import { Context } from '../../utils/context/store/Store';
import BaseLayOut from '../../components/Layout/BaseLayOut';
import useManageClientVitals from '../../utils/hooks/useManageClientVitals';
import usePagePermissions from '../../utils/hooks/usePagePermissions';
import Skelton from '../../components/Skelton/defaultSkelton';
const ClientVitalsView = Loadable({
  loader: () => import('./components/ClientVitalsView'),
  loading: () => <Skelton />
});

export default function ManageClientVitals({ routeKey, pageName }) {
  const { fetchClientVitals, isLoading } = useManageClientVitals(routeKey);
  const [{ clientVitals }, dispatch] = useContext(Context);
  const { getPermissionsForPage } = usePagePermissions(routeKey);
  const permissions = getPermissionsForPage();

  useEffect(async () => {
    await fetchClientVitals();

    return () => {};
  }, []);

  return (
    // <BaseLayOut
    //   pageTitle={pageName ?? 'Client Vitals'}
    //   pageView={tabValue}
    //   setTabValue={setTabValue}
    //   toggleText={'Client'}
    //   isForCv={true}
    // >
    <div className="__body__section __base__layout__container __manage__client__vitals__">
      <h3>Manage Client Vitals</h3>
      {Object.keys(clientVitals ?? {}).length ? (
        <ClientVitalsView
          clientVitals={clientVitals}
          isLoading={isLoading}
          permissions={permissions}
        />
      ) : (
        <Skelton />
      )}
    </div>

    // </BaseLayOut>
  );
}
