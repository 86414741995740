import React from "react";
import useNavigatingAway from "../../utils/hooks/useNavigationAway";
import LeaveCurrentPage from "./LeaveCurrentPage";

export default function DetectNavigationBlocker({isDataChanged, setIsDataChanged}){
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] = useNavigatingAway(isDataChanged)

  return (
    <LeaveCurrentPage showDialog={showDialogLeavingPage} setShowDialog={setIsDataChanged} confirmNavigation={confirmNavigation} cancelNavigation={cancelNavigation}/>
  )
}