import React, { useEffect, useContext } from 'react';
import Loadable from 'react-loadable';
import BaseLayOut from '../../components/Layout/BaseLayOut';
import { Context } from 'utils/context/store/Store';
import useManageNotifications from 'utils/hooks/useManageNotifications';
import usePagePermissions from 'utils/hooks/usePagePermissions';
import './notification-management.scss';
import Skelton from '../../components/Skelton/defaultSkelton';
const ManageNotificationGrid = Loadable({
  loader: () => import('./components/manage-notification-grid'),
  loading: () => <Skelton />
});

const NotificationManagement = ({ routeKey, pageName }) => {
  const { getPermissionsForPage } = usePagePermissions(routeKey);
  const [{ notificationsManagement }] = useContext(Context);
  const { getNotifications, updateOrCreateNotifications, isLoading } =
    useManageNotifications(routeKey);

  const getNotificationsList = async () => {
    await getNotifications();
  };

  useEffect(() => {
    getNotificationsList();
  }, []);

  return (
    // <BaseLayOut pageTitle={pageName ?? 'Manage Notification'}>
    <div className="__settings__container__right__section__">
      <ManageNotificationGrid
        columns={notificationsManagement.columns}
        rows={notificationsManagement.rows}
        loading={isLoading}
        dropDownData={notificationsManagement.dropdownValues}
        permissions={getPermissionsForPage(routeKey)}
        routeKey={routeKey}
        hitApi={updateOrCreateNotifications}
      />
    </div>
    // </BaseLayOut>
  );
};

export default NotificationManagement;
