import request from "../services/Http";
import { useContext, useState } from "react";
import { Context } from "../context/store/Store";
import { SET_IS_LOADING, SET_FINANCIAL_ATTRIBUTE, SET_FINANCIAL_ATTRIBUTE_LIST } from "../context/store/Constants";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    isJSONValid,
    addKeyAndIndexFieldToGenericArrayOfObjects,
    getPageProperties, getTableColumns
} from "../services/Helpers";
import { useIsLoading } from "./useIsLoading";

export default function useFinancialAttributeManagement(routeKey) {

    const [{ financialAttribute, pagesAndPermissions }, dispatch] = useContext(Context)
    const { isLoading, setIsLoading } = useIsLoading()
    const [localFaData, setLocalFaData] = useState({});
    const API_ENPOINT = 'rf-attribute-management'

    async function getFinancialAttributeManagementList() {
        setIsLoading(true)
        dispatch({ type: SET_IS_LOADING, payload: true })
        try {
            const res = await request.get(`${API_ENPOINT}`)
            let rows = res.data.data ?? []
            dispatch({ type: SET_FINANCIAL_ATTRIBUTE_LIST, payload: rows });

        }
        catch (e) { }
        setIsLoading(false)
        dispatch({ type: SET_IS_LOADING, payload: false })
    }

    /**
     * @param data_by
     * @param val
     * function used to manage get api call for attribute Management
     **/
    async function getFinancialAttributeManagement(data_by, localState = false) {
        setIsLoading(true)
        if (!localState) dispatch({ type: SET_IS_LOADING, payload: true });
        try {
            const res = await request.get(data_by)
            const properties = getPageProperties(pagesAndPermissions, routeKey)
            const table = getTableColumns(properties.page.table, data_by)
            let columns = table.columns
            let rows = isJSONValid(res.data.data) ?? {}
            let dropdownValues = isJSONValid(res.data.dropdownValues) ?? {}
            let dependencyCount = isJSONValid(res.data.dependencyCount) ?? {}
            let uploadTemplateLink = res.data.uploadTemplate ?? null
            let orgStructureLink = res.data.orgStructureLink ?? null
            let tableName = res.data.tableName ?? null

            if (financialAttribute && financialAttribute.hasOwnProperty('apiDelete') && financialAttribute.apiDelete) {
                rows = [...financialAttribute.rows, ...rows]
            }

            const dataTableData = { columns, rows: addKeyAndIndexFieldToGenericArrayOfObjects(rows), dropdownValues: dropdownValues, apiDelete: false, uploadTemplateLink, orgStructureLink, tableName }
            if (!localState)
                dispatch({
                    type: SET_FINANCIAL_ATTRIBUTE,
                    payload: dataTableData
                });
            else setLocalFaData(dataTableData);
        }
        catch (e) { }
        setIsLoading(false)
        if (!localState) dispatch({ type: SET_IS_LOADING, payload: false })
    }

    async function updateOrCreateFinancialAttributeManagement(data, val, isFromModal = false) {
        setIsLoading(true)
        dispatch({ type: SET_IS_LOADING, payload: true })
        try {
            const res = await request.post(val, { attributeManagement: data })
            toast.success(res.data.data)
            if (res && !isFromModal)
                await getFinancialAttributeManagement(val)
        }
        catch (e) { }
        setIsLoading(false)
        dispatch({ type: SET_IS_LOADING, payload: false })
    }

    /**
     * @param data
     * @param val
     * function used to manage delete api call for attribute Management
     **/
    async function deleteFinancialAttributeManagement(data, val, isFromModal) {
        setIsLoading(true)
        dispatch({ type: SET_IS_LOADING, payload: true })
        try {
            const res = await request.delete(val, {
                data: {
                    attributeManagement: data
                }
            })
            if (res && !isFromModal) await getFinancialAttributeManagement(val)
            toast.success(res.data.data);
        }
        catch (e) { }
        setIsLoading(false)
        dispatch({ type: SET_IS_LOADING, payload: false })
    }

    return { getFinancialAttributeManagementList, getFinancialAttributeManagement, updateOrCreateFinancialAttributeManagement, deleteFinancialAttributeManagement, isLoading, localFaData }
}