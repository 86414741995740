import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import Loadable from 'react-loadable';
import { Context } from 'utils/context/store/Store';
import { SelectBox } from 'devextreme-react/select-box';
import useWorkflows from 'utils/hooks/useWorkflows';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import usePagePermissions from '../../utils/hooks/usePagePermissions';
import { Grid } from '@mui/material';
import Select from 'react-select';
import { selectCustomStyles, workflowAccessOptions } from '../../utils/services/Helpers';
import useWorkflowStep from 'utils/hooks/useWorkflowStep';
import CustomSkelton from 'components/Skelton/CustomSkelton';
import loadingGif from 'assets/images/gifs/loading.gif';
import { Image } from 'antd';
import useBulkUpload from 'utils/hooks/useBulkUpload';
import Skelton from '../../components/Skelton/defaultSkelton';
const WfDataGrid = Loadable({
  loader: () => import('./DataTable/WfDataGrid'),
  loading: () => <Skelton />
});

function ManageWorkflows({ routeKey, pageName }) {
  const [value, setValue] = useState(null);
  const [displayValue, setDisplayValue] = useState(null);
  const [dValue, setDValue] = useState(null);
  const [currentRec, setCurrentRec] = useState({});

  const { getWorkflows, deleteWorkflows, updateOrCreateWorkflows, isLoading } =
    useWorkflows(routeKey);
  const { getTableData } = useWorkflowStep(routeKey);
  const { bulkUpload } = useBulkUpload(routeKey);
  const { getPermissionsForPage } = usePagePermissions(routeKey);

  const [{ workflows, workflowStepTableData }, dispatch] = useContext(Context);

  useEffect(async () => {
    if (value !== null) {
      if (value !== 'workflow-access') await getTableData();
      await getWorkflows(value);
    }
  }, [value]);

  const setEditState = (data) => {
    setCurrentRec(data);
  };

  const selectBoxHandle = useCallback(
    (e) => {
      if (e.event !== undefined) {
        setValue(e.value);
        const indexInArray = workflowAccessOptions?.findIndex(
          (eFindIndex) => eFindIndex.route === e.value
        );
        indexInArray !== -1 && setDisplayValue(workflowAccessOptions?.[indexInArray].label);
      }
    },
    [workflowAccessOptions, value, displayValue, setValue, setDisplayValue]
  );

  /**
   * custom function using useMemo to avoid re-renders unless the states listed are changed
   **/
  const comp = useMemo(() => {
    return (
      <div className="__settings__container__right__section__">
        <SelectBox
          width={300}
          placeholder={'Please select option... '}
          dataSource={workflowAccessOptions}
          value={value ?? null}
          searchEnabled={true}
          showClearButton={true}
          valueExpr="route"
          displayExpr="label"
          onValueChanged={(e) => selectBoxHandle(e)}
          className={`__select__box__ __mt__15`}
        />
        {value ? (
          <>
            <br />
            {workflows.rows && workflows.columns ? (
              <>
                <h3>Manage {displayValue}</h3>
                <WfDataGrid
                  loading={isLoading}
                  columns={workflows.columns}
                  rows={workflows.rows}
                  hitApi={updateOrCreateWorkflows}
                  fetchFromApi={getWorkflows}
                  handleDelete={deleteWorkflows}
                  isWorkflows={value === 'workflow'}
                  permissions={getPermissionsForPage(routeKey)}
                  dropDownData={workflows.dropdownValues}
                  valueToFetch={value}
                  isAccess={value === 'workflow-access'}
                  workflowStepTableData={workflowStepTableData}
                  currentRec={currentRec}
                  setCurrentRec={setEditState}
                  bulkUploadApi={bulkUpload}
                  apiCallback={getWorkflows}
                  tableName={workflows.tableName}
                  uploadTemplateLink={workflows.uploadTemplateLink}
                  orgStructureLink={workflows.orgStructureLink}
                  allowBulkUploading={value === 'workflow-access'}
                />
              </>
            ) : (
              <Skelton />
            )}
          </>
        ) : null}
      </div>
    );
  }, [value, workflows, workflowStepTableData, currentRec]);

  return <>{comp}</>;
}

export default ManageWorkflows;
