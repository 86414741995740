import { useState, useContext, useEffect } from "react";
import { SET_IS_LOADING, SET_CLIENT_VITAL } from "../context/store/Constants";
import { Context } from "../context/store/Store";
import {
  addKeyAndIndexFieldToGenericArrayOfObjects,
  isJSONValid,
  getPageProperties,
  getTableColumns,
} from "../services/Helpers";
import request from "../services/Http";
import { useIsLoading } from "./useIsLoading";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";

const CLIENT_VITAL_ENDPOINT = "cv?status=true";

export default function useManageClientVital(routeKey) {
  const [{ clientVital, pagesAndPermissions }, dispatch] = useContext(Context);
  const { isLoading, setIsLoading } = useIsLoading();
  const { clientId, entityId, id } = useParams()
  const [localClientVitals, setLocalClientVitals] = useState(null);

  useEffect(() => { }, [localClientVitals])


  async function getClientVitals(localState = false, enableGlobalLoader = true, tabValue = 0) {
    if (localState) setIsLoading(true);
    else if (enableGlobalLoader)
      dispatch({ type: SET_IS_LOADING, payload: true });
    try {
      const res = await request.get(CLIENT_VITAL_ENDPOINT);
      const properties = getPageProperties(pagesAndPermissions, "manage-client-vital");
      const table = getTableColumns(properties.page.table, "manage-client-vital");
      let columns = res.data.columns ?? [];
      const viewClientVitalsTable = getTableColumns(properties.page.table, "view-client-vital");
      // let viewClientVitalsColumns = [...columns]
        // viewClientVitalsTable.columns;
      let viewClientVitalsColumns = viewClientVitalsTable.columns;

      let data = isJSONValid(res.data.data) ?? {};
      let transactionHistories = res.data.histories
      let dropdownValues = isJSONValid(res.data.dropdownValues) ?? {};

      if (clientVital && clientVital.hasOwnProperty("apiDelete") && clientVital.apiDelete) {
        data = [...clientVital.data, ...data];
      }

      const colorsColumn = {
        type: "radio-group",
        title: "Colors",
        width: "250px",
        editable: true,
        ellipsis: false,
        required: true,
        dataIndex: "masterCvLightId",
        is_visible: true,
        is_sortable: true,
        is_searchable: true,
      };
      const nameColumn = {
        "type": "string",
        "title": "User",
        "width": "250px",
        "editable": false,
        "ellipsis": false,
        "required": true,
        "dataIndex": "userName",
        "is_visible": true,
        "is_sortable": true,
        "is_searchable": true
      }
      const dateColumn = {
        "type": "date",
        "title": "Date",
        "width": "250px",
        "editable": false,
        "ellipsis": false,
        "required": true,
        "dataIndex": "createdAt",
        "is_visible": true,
        "is_sortable": true,
        "is_searchable": true
      }
      const KPIColumn = {
        "type": "string",
        "title": "KPI",
        "editable": false,
        "ellipsis": false,
        "required": true,
        "dataIndex": "masterCvItemId",
        "is_visible": true,
        "is_sortable": true,
        "is_searchable": true
      }

      if (!columns.some((e) => e.type === "radio-group")) columns.splice(2, 0, colorsColumn);


      /*if (!viewClientVitalsColumns.some((e) => e.dataIndex === "masterCvItemId")) viewClientVitalsColumns.splice(1, 0, KPIColumn);

      viewClientVitalsColumns.push(nameColumn, dateColumn)*/

      const dgData = {
        columns,
        data: addKeyAndIndexFieldToGenericArrayOfObjects(data),
        viewClientVitalsColumns: viewClientVitalsColumns.filter(d => d.dataIndex !== "name"),
        dropdownValues,
        apiDelete: false,
        tabValue,transactionHistories
      };

      if (localState) setLocalClientVitals(dgData)
      else
        dispatch({
          type: SET_CLIENT_VITAL,
          payload: dgData,
        });
    } catch (e) { }
    finally{
      if (localState) setIsLoading(false);
      else if (enableGlobalLoader) dispatch({ type: SET_IS_LOADING, payload: false });
    }
  }

  async function updateOrCreateClientVital(data, tabValue) {
    setIsLoading(true);
    // dispatch({ type: SET_IS_LOADING, payload: true });
    try {
      const res = await request.post(CLIENT_VITAL_ENDPOINT, { clientVitals: data });
      if (res) {
        toast.success(res.data.data);
        await getClientVitals(true, false, tabValue);
      }
    } catch (e) { }
    finally{
      setIsLoading(false);
    }
    // dispatch({ type: SET_IS_LOADING, payload: false });
  }

  async function deleteClientVital(data) {
    setIsLoading(true);
    dispatch({ type: SET_IS_LOADING, payload: true });
    try {
      const res = await request.delete("cv-light", {
        data: { lights: data },
      });
      if (res) {
        toast.success(res.data.data);
        await getClientVitals();
      }
    } catch (e) { }
    finally {
      setIsLoading(false);
      dispatch({ type: SET_IS_LOADING, payload: false });
    }
  }

  return { getClientVitals, deleteClientVital, updateOrCreateClientVital, localClientVitals, isLoading, setIsLoading };
}
